import React from 'react';
import Grid from '@mui/material/Grid2';
import { Typography } from '@mui/material';

import styles from './projectQuality.module.css';
import { isMobile } from 'react-device-detect';

const SafetyOrder = () => {

    return (
        <Grid className={`${styles.safety_container}`} 
            sx={{paddingBottom: !isMobile ? "120px" : "80px", paddingTop: !isMobile ? "120px" : "10px"}}>
            <Grid className={styles.safety_subcontainer}>
                { isMobile && <Typography variant="h2" component={"h2"} className={styles.safety_title} sx={{marginBottom:"2rem"}}>Safety</Typography>}
                <Grid className={styles.safetyorder}>
                    <Grid className={styles.safetyContainer} sx={{ width: {xs:"100%", sm: "45%"}}}>
                        <Grid sx= {{display:"flex", justifyContent: isMobile? "space-evenly" :"space-between", columnGap:isMobile? "30px" : "40px"}}>
                            <Grid>
                                <Grid className={styles.safety_card}> 
                                    <img src="/assets/images/safety-at-work_icon.png" alt=" " />
                                </Grid>
                                <Typography variant="body2" component={"p"} 
                                    className={styles.safetybar_content}> {!isMobile? "Safety-first" : "Safety-first" }</Typography>
                            </Grid>
                            <Grid>
                                <Grid className={styles.safety_card}> 
                                    <img src="/assets/images/checking_icon.png" alt=" " />
                                </Grid>
                                <Typography variant="body2" component={"p"} 
                                    className={styles.safetybar_content}>Regulatory<br/>compliance</Typography>
                            </Grid>
                        </Grid>
                        <Grid sx= {{display:"flex", justifyContent:isMobile? "space-evenly" :"space-between", columnGap: isMobile? "30px" : "40px", marginTop:"2rem"}}>
                            <Grid>
                                <Grid className={styles.safety_card}> 
                                    <img src="/assets/images/risk-management_icon.png" alt=" " />
                                </Grid>
                                <Typography variant="body2" component={"p"} 
                                    className={styles.safetybar_content}>Risk mitigation</Typography>
                            </Grid>
                            <Grid>
                                <Grid className={styles.safety_card}> 
                                    <img src="/assets/images/insurance-audit_icon.png" alt=" " />
                                </Grid>
                                <Typography variant="body2" component={"p"} 
                                    className={styles.safetybar_content}>Safety audits</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid sx={{width: {xs:"100%", sm: "55%"}}}>
                        {!isMobile && <Typography variant="h3" component={"h3"} className={styles.safety_title}>Safety</Typography>}
                        <Typography variant="body2" component={"p"} className={styles.safety_content} sx={{marginTop: "1rem"}}>
                            Our safety practices are built on four pillars: Safety First, Regulatory Compliance, Risk Mitigation, and Safety Audits. By embedding safety into our organizational DNA, we ensure that every project meets the strictest safety standards. 
                            <br/><br/>
                            We engage in comprehensive risk assessments, maintain up-to-date regulatory adherence, and conduct regular safety audits to prevent incidents and ensure a hazard-free workplace. Safety is not just a goal—it’s a responsibility we take seriously at every level of our operations.
                        </Typography>
                    </Grid>
                </Grid>
                <img src= {!isMobile ? "/assets/images/safety_babber.png" : "/assets/images/safety_babber_mobile.png"} alt="" style={{width: !isMobile? "100%" : "fit-content", objectFit:'cover', marginLeft: isMobile? "-18px":"0px", marginRight: isMobile? "-18px":"0px", marginTop: isMobile ? "2rem" : "0px"}} />
             </Grid>
        </Grid>
    )


}

export default SafetyOrder;