import React from 'react';
import Grid from '@mui/material/Grid2';
import PageBanner from '../../components/pageBanner';
import AdvantageC from '../../components/advantageC';
import PageContent from '../../components/pageContent';
import ExploreProject from '../../components/exploreProject';
import ProjectClient from './projectClient';
import ExpertiseArea from './expertiseArea';
import DeliveryApproach from './deliveryApproach';


const Expertise = () => {

    const bannerPath = [
        {title: 'Home', url: '/'},
        {title: "Expertise", url: ''}
    ]


return (
    <Grid sx={{width: "100%"}}>
        <PageBanner title="Expertise" bannerimage="expertise_banner.jpg" 
            bannerPath={bannerPath}  />
        <PageContent 
           details="At Simon India, we bring over three decades of expertise across critical sectors, offering comprehensive Engineering, Procurement, and Construction (EPC) services. Our solutions are customized to meet the unique challenges of each sector, delivering excellence through innovation, precision, and a commitment to sustainability. Explore our diverse capabilities across Chemicals, Fertilizers, Oil & Gas, and Renewable Energy, where we drive industry-leading projects that shape a sustainable future." />
        {/* <ExpertiseProject /> */}
        <ExpertiseArea />
        <ProjectClient 
            quotation="At Simon India, value creation has been a core principle since the beginning. As a leading EPCM company, Simon stands out by fostering a unique blend of cultural values. The trust placed in its skilled employees empowers them to 'think right, act right,' making each individual feel valued and integral to the company’s success."
            client="Mr. Harish Kumar"
            designation="Business Development Head- Simon India Limited. "
        />
        <DeliveryApproach />
        <ExploreProject />
        <AdvantageC />
    </Grid>
)

}

export default Expertise;