import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Grid from '@mui/material/Grid2';
import Axios from 'axios';

import { Typography, Button  } from '@mui/material';

import styles from './supplyForm.module.css';
// import { } from './supplyForm.css';
import { East } from '@mui/icons-material';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { isMobile } from 'react-device-detect';
import { validateEmail } from '../../../utils/util';


const EmpanelForm = () => {

    const location = useLocation();
    // const {name} = useParams();
    // const [requirement, setRequirement] = useState(-1);
    const [validate, setValidate] = useState({
        bool: false,
        msg: ''
    });
    const pathPart = location.pathname.split("/");
    const navurl = pathPart[pathPart.length-1];
    const [status, setStatus] = useState(false);

    const [userInform, setUserInform] = useState({
        fname: '',
        lname:'',
        usermail: '',
        usertitle: '',
        userphone: '',
        usermessage:'',
    })

    const [firstInform, setFirstInform] = useState ({
        fname: false,
        lname:false,
        usermail: false,
        usertitle: false,
        userphone: false,
        usermessage: false,
    })
   
    
    const inputStyle = {
        fontFamily:'Kumbh Sans !important',
        fontSize:'1em',
        color:'#313131 !important',
        backgroundColor:'#FFF',
        border:'1px solid #CACACA',
        borderRadius:'5px',
        height:'1rem',
        width:'100%',
        padding: '1em',
        // marginBottom: '1.5em'
    }

      const handleSubmit = (event) => {

        const { fname, lname, usermail, usertitle, userphone, usermessage } = userInform;
        if(fname.trim() === '' ) { setValidate({bool:true, msg:'First name can not be blank.'});  return; }
        if(lname.trim() === '' ) { setValidate({bool:true, msg:'Last name can not be blank.'});  return; }
        if(usertitle.trim() === '') {setValidate({bool:true, msg:'Title can not be blank.'}); return;} 
        if(userphone.trim() === '') {setValidate({bool:true, msg:'Phone can not be blank.'}); return;} 
        if(usermessage.trim() === '') {setValidate({bool:true, msg:'Message can not be blank.'}); return;} 
        if(usermail.trim() === '' || !validateEmail(usermail) ) {setValidate({bool:true, msg:'Write correct email.'}); return;}

        Axios.post('/supplymail.php', {name: `${fname} ${lname}`, email:usermail, title: usertitle, phone: userphone, msg: usermessage })
        .then(res => console.log('res ', res))
        .catch(error => console.log('error ', error))
        
        setStatus(true)


      }
      const callUser = (event) =>{
        event.preventDefault();
        setStatus(false);
        const {id, value} = event.target;
        const tempUserInform = {...userInform};
        tempUserInform[id] = value
        setUserInform(tempUserInform);

        const tempFirstInform = {...firstInform}
        tempFirstInform[id] = true;
        setFirstInform(tempFirstInform);
    }


    return (
        <Grid className= {styles.supplyForm_container} 
            sx={{paddingTop:"0px", paddingBottom:"120px", flexFlow:"column", alignItems:'center'}}>
            <Grid className={styles.supplyForm_tabcontainer} 
                sx={{borderBottom:"1px solid #CEE86B"}}>
                    <img src= {isMobile ? "/assets/images/supply_tab3_mobile.jpg" : "/assets/images/supply_tab3.jpg"} alt="" />
                    <Grid sx={{backgroundColor:"#ECF2F6", padding: !isMobile? "4rem 7rem 0rem": "4rem 0rem 0rem"}} >
                        <Grid sx={{marginBottom:"2rem",paddingLeft: isMobile? "18px":"0px", paddingRight: isMobile? "18px":"0px" }}>
                            <Typography variant="h3" component={"h3"} className={styles.supplyForm_title}>
                            Empanel as a SME
                            </Typography>
                        </Grid>
                        <Grid className={styles.supplyForm_subcontainer}
                            sx={{display:"flex", flexFlow: isMobile?"column-reverse":"row", gap:"2rem", paddingLeft: isMobile? "18px":"0px", paddingRight: isMobile? "18px":"0px" }} >
                            <Grid sx={{width:{xs:"100%", sm:"60%"}}}>
                                <Typography variant="body2" component={"p"} className={styles.supplyForm_content}>
                                    At Simon India, we value the expertise of seasoned professionals. As a subject matter expert, you’ll collaborate with us on large-scale, industry-leading projects. 
                                    <br/><br/>
                                    Partnering with Simon India means expanding your professional network, and shaping the future of engineering. Benefit from our extensive industry reach, a culture of excellence, and the opportunity to make a lasting impact on global projects.
                                </Typography>
                            </Grid>
                            <Grid sx={{width:{xs:"100%", sm:"40%", display:"flex", justifyContent:"center", alignItems:"center"}}}>
                                <img src="/assets/images/supplytab3_icon.png" alt=""/>
                            </Grid>
                        </Grid>
                        <Grid sx={{position:"relative", width:"100%", height:"100px", display:"flex", justifyContent:"center"}}>
                            <Button className={styles.provide_btn} endIcon={<KeyboardDoubleArrowDownIcon /> } >Provide the information</Button>
                        </Grid>
                        
                    </Grid>
            </Grid>
            <Grid className={styles.supplyForm_subcontainer} 
                sx={{marginTop: "120px", paddingLeft: isMobile? "18px":"0px", paddingRight: isMobile? "18px":"0px" }}>
                <Typography variant="h3" component={"h3"} className={styles.supplyForm_title}>
                    About Your Organization
                </Typography>
                <Typography variant="body2" component={"p"} className={styles.applyForm_content}>
                    All fields are mandatory
                </Typography>
                <Grid sx={{marginTop:"2rem"}}>
                    <form>
                        { !status && <>
                            <Grid sx={{width:{xs:"90%", sm:"70%"}}}>
                                <Grid sx={{marginBottom: '1.5em'}}>
                                    <label htmlFor='fname' className={styles.supplyForm_content}>First name:</label>
                                    <input type='text' id='fname' name='fname' value={userInform.fname} placeholder='' 
                                        onChange={callUser} style={inputStyle} />
                                    <div style={{color:"red"}}>{firstInform["fname"] === true && userInform["fname"].trim() === "" ? "First name can not be blank":""}</div>
                                </Grid>
                                <Grid sx={{marginBottom: '1.5em'}}>
                                    <label htmlFor='lname' className={styles.supplyForm_content}>Last name:</label>
                                    <input type='text' id='lname' name='lname' value={userInform.lname} placeholder='' 
                                        onChange={callUser} style={inputStyle} />
                                    <div style={{color:"red"}}>{firstInform["lname"] === true && userInform["lname"].trim() === "" ? "Last name can not be blank":""}</div>
                                </Grid>
                                <Grid sx={{marginBottom: '1.5em'}}>
                                    <label htmlFor='usermail' className={styles.supplyForm_content}>Email company:</label>
                                    <input type='text' id='usermail' name='usermail' value={userInform.usermail} placeholder='' required autoComplete='off'
                                        onChange={callUser} style={inputStyle} />
                                    <div style={{color:"red"}}>{firstInform["usermail"] === true && userInform["usermail"].trim() === "" ? "Email can not be blank":""}</div>
                                </Grid>
                                <Grid sx={{marginBottom: '1.5em'}}>
                                    <label htmlFor='usertitle' className={styles.supplyForm_content}>Title:</label>
                                    <input type='text' id='usertitle' name='usertitle' value={userInform.usertitle} placeholder='' required autoComplete='off'
                                        onChange={callUser} style={inputStyle} />
                                    <div style={{color:"red"}}>{firstInform["usertitle"] === true && userInform["usertitle"].trim() === "" ? "Title can not be blank":""}</div>
                                </Grid>
                                <Grid sx={{marginBottom: '1.5em'}}>
                                    <label htmlFor='userphone' className={styles.supplyForm_content}>Phone number:</label>
                                    <input type='text' id='userphone' name='userphone' value={userInform.userphone} placeholder='' required autoComplete='off'
                                        onChange={callUser} style={inputStyle} />
                                    <div style={{color:"red"}}>{firstInform["userphone"] === true && userInform["userphone"].trim() === "" ? "Phone number can not be blank":""}</div>
                                </Grid>
                                <Grid sx={{marginBottom: '1.5em'}}>
                                    <label htmlFor='usermessage' className={styles.supplyForm_content}>Message:</label>
                                    <textarea type='text' id='usermessage' name='usermessage' value={userInform.usermessage} placeholder='' required
                                        onChange={callUser} style={inputStyle} />
                                    <div style={{color:"red"}}>{firstInform["usermessage"] === true && userInform["usermessage"].trim() === "" ? "Message can not be blank":""}</div>
                                </Grid>
                            </Grid>
                            </> 
                        }
                            {validate.bool && !status && <Typography variant='body1' component={'p'} className='normal-text text-color' sx={{py:1, color: "red"}} >{validate.msg}</Typography>}
                            {status && <Typography variant='body2' component={'h4'} className='normal-text text-color' sx={{fontSize:'1.5 !important'}} >Thank you, your information has been sent. We will be in touch shortly.</Typography>}
                            {!status && <Grid item sx={{display:'flex', columnGap:'0.5em', alignItems:'center', justifyContent:'flex-start', mt:'2em', width:'100%'}}>
                                <Button variant='contained' 
                                    className= {styles.supplyForm_btn}
                                    endIcon={<East color="#FFFFFF" />}
                                    onClick={handleSubmit}
                                >Submit form </Button>
                            </Grid>
                        }
                            
                    </form>
                </Grid>

            </Grid>
        </Grid>
    )

}

export default EmpanelForm;