import React from 'react';
import Grid from '@mui/material/Grid2';
import { Typography } from '@mui/material'; 

import styles from './aboutus.module.css';


const AboutCustomer = () => {

    return (
        <Grid className={styles.customer_container}>
            <Grid className={styles.customer_subcontainer}>
                <img src="/assets/images/aboutcustomerarrow.png" alt="" />
                <Grid sx={{width:"90%"}}>
                    <Typography variant='h3' component={"h3"} className={styles.customer_title}>
                        For over 27 years, Simon India has proudly built a legacy rooted in trust, strong values, and a shared commitment to excellence. As an EPCM leader, we are passionate about delivering tailored solutions across industries, powered by the dedication and expertise of our talented team of engineers. Their knowledge and hard work are at the heart of everything we do.
                        <br/><br/>
                        As we move forward, we remain deeply committed to upholding the integrity and passion that have always defined Simon India, and to creating value for our clients and partners, now and in the future.
                    </Typography>
                    <Typography variant='body2' component={"p"} className={styles.customer_desig}>
                        Mr. Athar Shahab
                    </Typography>
                    <Typography variant='body2' component={"p"} className={styles.customer_location}>Non Executive Director, Simon India Limited & MD- Zuari Industries Limited</Typography>

                </Grid>
            </Grid>
        </Grid>
    )


}

export default AboutCustomer;