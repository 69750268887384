import React, { useRef } from 'react';
import Grid from '@mui/material/Grid2';
import { Typography, IconButton } from '@mui/material';
import { East, West } from '@mui/icons-material';

import Slider from 'react-slick';
import { isMobile } from 'react-device-detect';

import styles from './ourculture.module.css';
import { } from './ourculture.css'


const CulturePeople = () => {

    const sliderRef = useRef(null);

    
    const settings = {
        dots: false,
        arrows: false,
        centerPadding: '30px',
        infinite: true,
        speed: 1000,
        slidesToShow: 2,
        slidesToScroll: 2,
        centerMode: false,
        // autoplay: true,
        // autoplaySpeed: 100,
        responsive: [
            { 
            breakpoint: 1025,
                setting: {

                }
            },
            {
                breakpoint: 450,
                settings: {
                    dots: true,
                    arrows: false,
                    centerMode: false,
                    infinite: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    }
    const mobileCultureData = [
            <div style={{width: "100%"}}><img src="/assets/images/gallery/culture_gallery_img1.png" alt="g1"  style={{width: "97%"}}/></div>,
            <div style={{width: "100%"}}><img src="/assets/images/gallery/culture_gallery_img2.png" alt="g2" style={{width: "94%"}}/></div>,
            <div style={{width: "100%"}}><img src="/assets/images/gallery/culture_gallery_img3.png" alt="g3" style={{width: "97%"}}/></div>,
            <div style={{width: "100%"}}><img src="/assets/images/gallery/culture_gallery_img4.png" alt="g4" style={{width: "94%"}}/></div>
    ]

    const slidePrev = (e) => {
        e.preventDefault()
        sliderRef.current.slickPrev();
    }
    const slideNext = (e) => {
        e.preventDefault()
        sliderRef.current.slickNext();
    }

    return (
        <>
        <Grid className={styles.culture_container} 
            sx={{paddingTop: "120px", justifyContent:'flex-start !important'}}>
            <Grid className={`${styles.culture_subcontainer} ${styles.culture_image_container}`} 
                sx={{}} >
                    <Slider ref={sliderRef} {...settings}>
                        {mobileCultureData}
                    </Slider>
            </Grid>
        </Grid>
        <Grid className={styles.culture_container} 
            sx={{paddingTop: "1.5rem", justifyContent:'flex-start !important', paddingLeft: isMobile?"0px" :"", paddingRight: isMobile?"0px" :""}}>
            <Grid className={`${styles.culture_subcontainer} ${styles.culturepeople_container}`} >
                
                <Grid sx={{width:{xs:"100%", sm:"50%"}, marginTop: isMobile ? "3rem" :"0px"}}>
                    <img src="/assets/images/peopleculture_img.png" alt="" style={{width: isMobile?"100%": ""}} />
                </Grid>
                <Grid sx={{width:{xs:"100%", sm:"50%"}}}>
                    <div style={{display: isMobile ?"none":"flex", justifyContent:"flex-end"}}>
                        <IconButton onClick={slidePrev} sx={{color:"#2182FF"}}><West /></IconButton>
                        <IconButton onClick={slideNext} sx={{color:"#2182FF"}}><East /></IconButton>
                    </div>
                    <Grid sx={{paddingLeft: isMobile? "36px": "", paddingRight: isMobile? "36px": ""}}>
                        <Typography variant="h3" component={"h3"} className={styles.culture_title} sx={{marginBottom:"1.5rem"}}>
                            People Culture
                        </Typography>
                        <Typography variant="body2" component={"p"} className={`${styles.culture_content}`} sx={{width: isMobile? "100%": "70%",marginBottom:"1.5rem"}}>
                        Simon India’s people culture is built on the values of teamwork, mutual respect, and a shared commitment to excellence. Our employees collaborate closely, support one another, and celebrate successes together, creating a dynamic and positive work environment. 
    <br/><br/>
    Whether we’re tackling complex projects or enjoying team events, we believe in fostering strong relationships that drive both personal and professional growth.
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </>
)}
export default CulturePeople;
