import React, { useState, useRef } from 'react';
import Grid from '@mui/material/Grid2';
import { Card, CardContent, CardMedia, Link, Typography, Button } from '@mui/material';
import Slider from 'react-slick';
import { East, West } from '@mui/icons-material';
import { isMobile } from 'react-device-detect';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import styles from './leaderShip.module.css'
import { } from './leaderShip.css';

const Board = () => {
    const boardRef = useRef(null);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [heightBool, setHeightBool] = useState(false);

    const handleBoardMember = (e) => {
        console.log(e);
        setHeightBool(false);
        setSelectedIndex(e-1);
    }

    const boardTeamData = [
        {   id: 1,
            title: "Mr S.K. Poddar",
            designation: "Chairman-Adventz Group",
            linkedIn: "httsp://linkedin.in",
            description: `Mr Saroj Kumar Poddar, 77, a leading Indian industrialist of international repute, is the Chairman of the Adventz Group of Companies, a leading Indian conglomerate with a turnover in excess of US $3 billion, comprises 23 leading companies in various verticals, constituting key drivers of the Indian economy. The Group was repositioned under the Adventz banner by Mr Poddar, to leverage its exemplary equity in terms of knowledge, best practices and technical excellence. Under Mr Poddar, the group has promoted various seminal projects including joint ventures with leading international corporations. 
<br/><br/>
The most notable of these ventures are Paradeep Phosphates Ltd, a joint venture with OCP of Morocco; Hettich India Private Ltd, Hettich Middle East (P) Ltd, Hettich Africa (P) Ltd along with HePo India (P) Ltd, joint ventures with the Hettich Group of Germany and Wabtec Texmaco Rail Private Ltd, a joint venture with Westinghouse (Wabtec), USA.  Mr. Poddar was also instrumental in promoting Gillette India Ltd, a venture with the renowned ‘The Gillette Company,’ USA and was Founder Chairman of the Company since 1984, before relinquishing the position in December 2013. At Mr Poddar’s initiative, the group has acquired a controlling stake as well as management control of Mangalore Chemicals and Fertilisers Ltd; as also Kalindee Rail Nirman (Engineers) Ltd and Bright Power, both of which offer synergetic strength to its core railway products business.
<br/><br/>
At present, Mr Poddar is the Chairman of Zuari Agro Chemicals Limited, Zuari Global Limited, Chambal Fertilisers & Chemicals Limited, Paradeep Phosphates Limited, Texmaco Infrastructure & Holdings Limited and Texmaco Rail & Engineering Limited.
<br/><br/>
Additionally, Mr Poddar has also served on the Boards of prestigious TNCs (Trans National Corporations) such as Alstom Ltd. The global T&D business of Alstom was sold to the French nuclear major Areva and in India the company became Areva T&D India Ltd. At the behest of the French company, Mr Poddar accepted to continue as The Chairman of Areva T&D India Ltd and led the business as its Chairman for ten long years, until the year 2009. Mr Poddar also served as The Chairman of Zuari Cement Ltd – a wholly owned company of France-based manufacturer of cement, Ciments Francais (Italcementi Group) – for a period of four years during 2011 to 2015. 
<br/><br/>
Mr Poddar has received many accolades and awards for his contribution to business & economy, from prestigious institutions in India and other nations, at various occasions in the past. Mr Poddar was appointed Commander in the National Order of Merit (“Commandeur de l’Ordre national du Merite”) by the President of the French Republic in 2011 for playing an important role in setting up French firms in India and enhancing the relations between France and India in the field of economics. The Economic Times, conferred the “Lifetime Achievement Award 2017” on Mr Poddar at its Bengal Corporate Awards 2017 in recognition for his immense contribution to business growth, organizational transformation and setting forth exemplary industrial initiatives and trends. He is also a recipient of the Rashtriya Samman award from the Central Board of Direct Taxes in India.  
<br/><br/>
As a proactive leader and industrialist, Mr Poddar has represented as the Chairman of India-Saudi Arabia Joint Business Council and is a Member of the Indo-French CEO Forum. Back home in India, he has also represented as President of reputed industry fora such as FICCI and International Chamber of Commerce in India, and has been appointed by Government of India on the Board of Trade – the highest body on trade – as well as on the Court of the Indian Institute of Science, Bangalore. Mr Poddar has also served as a member of the Board of Governors of the Indian Institute of Technology, Kharagpur for over 10 years and on the local Board of the Reserve Bank of India for a similar period. He was also on the Advisory Board of one of the most reputed investment brokers, Messrs N M Rothschild & Sons (India) Pvt Limited.
<br/><br/>
A Gold Medallist in Commerce from Calcutta University, Mr Poddar is an aficionado of art, culture and sports, and is passionately involved in its promotion and development in the country. Both, Mr and Mrs Poddar follow their passion through Adventz Foundation, a non-profit institution set-up to for promotion of art, culture, sports and social development in the country. 
<br/><br/>
Adventz Foundation is actively involved in social work and Mr. Poddar has been guiding the Foundation’s activities. During the past five years, the community development initiatives of the group have impacted more than 150,000 lives of low income communities in villages & urban slums in Ten Indian States. These communities based initiatives primarily focus on strengthening women groups (SHGs) for initiatives in social Inclusion & livelihoods, and Skills Development, WASH (Water Sanitation Hygiene) & Primary Health. 
<br/><br/>
The efforts made through the Foundation have made incremental improvement in farm productivity and family incomes of small and marginal farmers across many states. The projects have empowered farmers by equipping them with the skills through training programs, crop demonstrations, agri-extension and farm based micro-enterprises. The group companies – ZACL (Zuari Agro Chemicals Limited), PPL (Paradeep Phosphates Limited) and Mangalore Chemicals & Fertilizers Limited (MCFL) have been constantly driving farmer welfare efforts based on the guiding principle, ‘give them a fish hook instead of fish’.  
<br/><br/>
Mr. Poddar is re-enforcing and driving the Group’s commitment to social and environmental development by investing in long-term efforts to support sustainable development and growth through effective interventions. He remains deeply committed to initiatives aimed at betterment of society. In order to maximize the social impact, Adventz Group Companies are making their best efforts to combine resources and business strengths of individual companies to achieve specific social objectives.`
        },
        {   id: 2,
            title: "Mr Athar Shahab",
            designation: "Director",
            linkedIn: "httsp://linkedin.in",
            description: `Mr Athar Shahab is serving as the Managing Director of Zuari industries
Limited, the apex company of the Adventz Group.
<br/><br/>
The Adventz Group is a 3 billion USD Indian conglomerate with over 75+
years of trust and legacy. The Group operates in four industry verticals:
Agribusiness, Engineering & Infrastructure, Real Estate & Lifestyle
and Services and gives employment to over 6,000 employees either
directly or indirectly.
<br/><br/>
Mr Shahab also serves on the boards of several subsidiaries/JVs of Zuari
Industries Limited. Mr Shahab has more than 34 years of rich experience across project
finance, advisory, investment, project management and general
management. Mr Shahab has made significant contribution towards private sector
partnerships in India.
<br/><br/>
Mr Shahab has held leadership positions at reputed companies such
as L&T, Vedanta Group, IDFC, AES and ICICI Limited in the past. Mr Shahab holds a degree in Civil Engineering from VSS University of
Technology, Burla (VSSUT) and a PG Diploma in Business Management
from XLRI.`
        },
        {   id: 3,
            title: "Mr. Tribhuvan Darbari",
            designation: "Director",
            linkedIn: "httsp://linkedin.in",
            description: `Mr. Tribhuvan Darbari is currently the Managing Director & CEO, Texmaco Defence Systems Pvt. Ltd. & Chief Executive, Texmaco Rail & Engineering Ltd. a flagship heavy engineering and manufacturing national player of the USD 3 Billion Adventz Group. He has around 37 years of rich & diversified experience in Corporate Strategy, Strategic Alliances & JVs, International Business Collaborations & Technology Partnerships, Commercial Operations, Project Management & conceptualizing strategic initiatives for business diversification and growth, during his assignments in Govt. and Industry, at senior levels.
<br /><br />
From 1987 onwards, he progressed from the levels of Dy. Secretary/Director/Joint Secretary in the Government of India (including under the Cabinet Secretariat, Ministry of Power, Ministry of Petroleum, Natural Gas & Chemicals, Ministry of Communications & Information Technology) & at the enterprise levels as General Manager/Vice President/Director, CEO under 5 Fortune 500 Corporations i.e. ONGC (A Govt. of India Enterprise), TATA Group, Hewlett Packard, Huawei Telecommunications & China National Petroleum Corporation (CNPC/CPP). He has contributed significantly as an active member of various important and strategic National/International Government and Corporate Committees, cutting across multiple sectors and geographies. He has been recently appointed Chairman of CII Committee on Defence and Aerospace, Eastern Region and Member of FICCI National Task Force on Defence and Aerospace.
<br /><br />
Mr. Darbari’s rich and varied experience, at senior levels, under the Ministry of Petroleum, Natural Gas, Chemicals and Petrochemicals, Pharmaceuticals and Fertilizers and under the Ministry of Energy, Department of Power, Govt. of India as well as General Manager ONGC and CEO China Petroleum (a subsidiary of China National Petroleum Corporation), has enabled him with a strategic global vision, knowledge, and he has build and nurtured valuable relationships, at the top Management levels, in the above sectors. He has also played a significant role as the Vice President of the Asia Arab Chambers of Commerce and the Director General Chindia Chambers of Commerce and Industry. His extensive and unique experience and exposure, can be gainfully utilized by the Simon India Ltd., for sustained growth of its business, both in India and Globally in the Arab World, Russia and amongst the SAARC and ASEAN Countries.
<br /><br />
Mr. Darbari Schooled at St. Columbus High School, New Delhi and after completing his Graduation & Post Graduation from St. Stephen’s College India, he has accomplished advanced management education and training from six prestigious Global Universities and Institutes in India, UK, Australia, Japan, Sweden and USA.`
        },
        {   id: 4,
            title: "Mr. Hemant Sahai",
            designation: "Director",
            linkedIn: "httsp://linkedin.in",
            description: `Hemant Sahai is B. Com (Hons.) and LLB from University of Delhi. He is the Founding Partner of HSA Advocates and is responsible for the firm’s strategic growth and development. In a career spanning over three decades, he has developed a distinct focus on projects, energy & infrastructure, regulatory disputes and governance issues impacting companies and their Boards. As a member of several government committees, He frequently advises ministries and regulatory authorities on refining the legal and policy framework in India. He advises companies and their Boards on a range of aspects such as enterprise risk, corporate governance, regulatory challenges, corporate restructuring and dispute strategy. He is a member of the Boards of several organizations, including public listed companies like Akzo Nobel India Limited, MB Power (Madhya Pradesh) Limited, SAEL Industries Limited. He is also a member of International Bar Association.
            <br/><br/>
Mr. Hemant Sahai has served as an adviser to several working groups and committees formed by top government bodies/institutions including certain extra ministerial policy advisory bodies set up by the Prime Minister’s Office, Ministry of Power, Ministry of New and Renewable Energy and other government bodies, from time to time. Mr. Sahai has advised the Planning Commission, NITI Aayog and other Governmental bodies on policy issues, drafting model transaction and policy documents.
<br/><br/>
Mr. Sahai was appointed to provide legal advisory services to the High-Power Committee constituted by the Government of Gujarat to give recommendations, pursuant to deliberations with the relevant stakeholders.`
        },
        {   id: 5,
            title: "Mr. Nitin Kantak",
            designation: "Director",
            linkedIn: "httsp://linkedin.in",
            description: `Mr. Nitin M. Kantak is Bachelor of Chemical Engineering from Institute of Chemical Technology (formerly UDCT), Mumbai and has over 40 years of experience in Nitrogenous and Phosphatic fertilizer industry in Plant Operations, Project Management & Commissioning, Process Engineering, and technical services. He started his career with Rashtriya Chemicals and Fertilizers Ltd, Trombay, before moving to Zuari Agro Chemicals Ltd in October 1982. He is associated with our Group and has worked in Zuari Agro Chemicals Limited since 1982. Presently he is a Whole-time Director of Mangalore Chemicals and Fertilisers Limited and Zuari Agro Chemicals Limited.`
        },
        {   id: 6,
            title: "Mr. Umesh Sood",
            designation: "Chief Executive Officer",
            linkedIn: "httsp://linkedin.in",
            description: `Mr. Umesh Sood is a Corporate Finance and Treasury professional with extensive experience of working across industries including financial services, telecom, steel, pipe sector, energy infrastructure, fertilizer and chemicals, equity markets, plywood etc. He has vast expertise in deal structuring, fund raising, from both equity and debt markets in India and overseas, investor relations, exim trade etc.
            <br/><br/>
            Mr. Sood is a B. Com Honors Graduate (Merit Holder) and MBA Finance from Himachal Pradesh University, Shimla and LL.B (Professional) from Punjab University, Chandigarh and a Member of the Bar Council of Himachal Pradesh. He has also been a visiting faculty to Punjab University Business School.
            <br/><br/>
            He joined Zuari Agro Chemicals Limited as Treasurer in April 2014 and worked in this capacity till June 2018 and has been associated with Zuari Global Limited as General Manager, Finance. He has also held additional charge as Chief Financial Officer of Zuari Investments Limited. He has worked in many reputed companies such as Kitply Industries Limited, Punwire Limited, IL&FS and Jindal Saw Limited.`
        }
    ]

    const NextArrow = props => {
        const { className, style, onClick } = props;
        return <East
        className={className}
        style={{ ...style, display: "block", right:"-8%", background: "transparent", color: "rgba(33, 130, 255, 1)" }}
        onClick={onClick}
      />
    }
    const PrevArrow = props => {
        const { className, style, onClick } = props;
        return <West
        className={className}
        style={{ ...style, display: "block", left:"-8%", background: "transparent", color: "rgba(33, 130, 255, 1)" }}
        onClick={onClick}
      />
    }

    const settings= {
        dots: false,
        arrows: true,
        dotsClass: 'slick-dots',
        className: 'center',
        centerMode: false,
        infinite: false,
        centerPadding: '30px',
        slidesToShow: 4,
        slidesToScroll: 1,
        swipeToSlide: false,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        speed: 500,
        responsive: [
           { breakpoint: 1025,
                settings: {

                }
            },
            { breakpoint: 450,
                settings: {
                    // infinite: true,
                    arrows: false,
                    dots: true,
                    centerMode: true,
                    slidesToShow: 1,
                    swipeToSlide: true,
                    afterChange: (e)=>{setHeightBool(false);setSelectedIndex(e)}
                }
            },
        ]
    }
    const boardData = [
        <>
            <Card className={styles.leader_card} 
                sx={{borderBottom: selectedIndex === 0 ? "10px solid #B0D22D" : "unset"}}
                data-id="1" 
                onClick={() => handleBoardMember(1)}
            >
                <Grid sx={{borderBottom: "10px solid #00258A", margin: isMobile? "0 0.5rem": "0"}}>
                    <CardMedia sx={{height: isMobile? 210 : 216, backgroundSize: isMobile ? 'contain':'cover'}}
                        className= {selectedIndex === 0 ? styles.leader_media_active : styles.leader_media}
                        title="Mr S.K. Poddar"
                        image="/assets/images/skpoddar.png"
                    />
                </Grid>
                <CardContent sx={{padding:"1rem 0px 0.5rem 0px", margin: isMobile? "0 1rem": "" }}>
                    <Typography variant="body2" component={"p"} className={`${styles.leader_card_title} ${selectedIndex === 0 ? styles.fade_title : ''}`}>Mr S.K. Poddar</Typography>
                    <Typography variant="body2" component={"p"} className={`${styles.leader_card_desig} ${selectedIndex === 0 ? styles.fade_title : ''}`}>Chairman-Adventz Group</Typography>
                </CardContent>
            </Card>
        </>,
        <>
        <Card className={styles.leader_card} data-id="2" onClick={() => handleBoardMember(2)}
            sx={{borderBottom: selectedIndex === 1 ? "10px solid #B0D22D" : "unset"}}
        >
            <Grid sx={{borderBottom: "10px solid #00258A", margin: isMobile? "0 0.5rem": "0"}}>
                <CardMedia
                    sx={{height: isMobile? 210 : 216, backgroundSize: isMobile ? 'contain':'cover'}}
                    title="Mr Athar Shahab"
                    className= {selectedIndex === 1 ? styles.leader_media_active : styles.leader_media}
                    image="/assets/images/athar.png"
                />
            </Grid>
            <CardContent sx={{padding:"1rem 0px 0.5rem 0px", margin: isMobile? "0 1rem": "" }}>
            <Typography variant="body2" component={"p"} className={`${styles.leader_card_title} ${selectedIndex === 1 ? styles.fade_title : ''}`}>Mr Athar Shahab</Typography>
            <Typography variant="body2" component={"p"} className={`${styles.leader_card_desig} ${selectedIndex === 1 ? styles.fade_title : ''}`}>Director</Typography>
            </CardContent>
        </Card>
        </>,
        <>
        <Card className={styles.leader_card} data-id="3" onClick={() => handleBoardMember(3)}
            sx={{borderBottom: selectedIndex === 2 ? "10px solid #B0D22D" : "unset"}}
        >
            <Grid sx={{borderBottom: "10px solid #00258A", margin: isMobile? "0 0.5rem": "0"}}>
                <CardMedia
                    sx={{height: isMobile? 210 : 216, backgroundSize: isMobile ? 'contain':'cover'}}
                    title="Tribhuvan"
                    className= {selectedIndex === 2 ? styles.leader_media_active : styles.leader_media}
                    image="/assets/images/darbari.png"
                />
            </Grid>
            <CardContent sx={{padding:"1rem 0px 0.5rem 0px", margin: isMobile? "0 1rem": "" }}>
                <Typography variant="body2" component={"p"} className={`${styles.leader_card_title} ${selectedIndex === 2 ? styles.fade_title : ''}`}>Mr. Tribhuvan Darbari</Typography>
                <Typography variant="body2" component={"p"} className={`${styles.leader_card_desig} ${selectedIndex === 2 ? styles.fade_title : ''}`}>Director</Typography>
            </CardContent>
        </Card>
        </>,
        <>
        <Card className={styles.leader_card} data-id="4" onClick={() => handleBoardMember(4)}
            sx={{borderBottom: selectedIndex === 3 ? "10px solid #B0D22D" : "unset"}}
        >
            <Grid sx={{borderBottom: "10px solid #00258A", margin: isMobile? "0 0.5rem": "0"}}>
                <CardMedia
                    sx={{height: isMobile? 210 : 216, backgroundSize: isMobile ? 'contain':'cover'}}
                    title="Mr Akshay Poddar"
                    className= {selectedIndex === 3 ? styles.leader_media_active : styles.leader_media}
                    image="/assets/images/hemant.png"
                />
            </Grid>
            <CardContent sx={{padding:"1rem 0px 0.5rem 0px", margin: isMobile? "0 1rem": "" }}>
            <Typography variant="body2" component={"p"} className={`${styles.leader_card_title} ${selectedIndex === 3 ? styles.fade_title : ''}`}>Mr. Hemant Sahai</Typography>
            <Typography variant="body2" component={"p"} className={`${styles.leader_card_desig} ${selectedIndex === 3 ? styles.fade_title : ''}`}>Director</Typography>
            </CardContent>
        </Card>
        </>,
        <>
        <Card className={styles.leader_card} data-id="5" onClick={() => handleBoardMember(5)}
            sx={{borderBottom: selectedIndex === 4 ? "10px solid #B0D22D" : "unset"}}
        >
            <Grid sx={{borderBottom: "10px solid #00258A", margin: isMobile? "0 0.5rem": "0"}}>
                <CardMedia
                    sx={{height: isMobile? 210 : 216, backgroundSize: isMobile ? 'contain':'cover'}}
                    title="Mr Akshay Poddar"
                    className= {selectedIndex === 4 ? styles.leader_media_active : styles.leader_media}
                    image="/assets/images/nitin.png"
                />
            </Grid>
            <CardContent sx={{padding:"1rem 0px 0.5rem 0px", margin: isMobile? "0 1rem": "" }}>
            <Typography variant="body2" component={"p"} className={`${styles.leader_card_title} ${selectedIndex === 4 ? styles.fade_title : ''}`}>Mr. Nitin Kantak</Typography>
            <Typography variant="body2" component={"p"} className={`${styles.leader_card_desig} ${selectedIndex === 4 ? styles.fade_title : ''}`}>Director</Typography>
            </CardContent>
        </Card>
        </>,
        <>
        <Card className={styles.leader_card} data-id="6" onClick={() => handleBoardMember(6)}
            sx={{borderBottom: selectedIndex === 5 ? "10px solid #B0D22D" : "unset"}}
        >
            <Grid sx={{borderBottom: "10px solid #00258A", margin: isMobile? "0 0.5rem": "0"}}>
                <CardMedia
                    sx={{height: isMobile? 210 : 216, backgroundSize: isMobile ? 'contain':'cover'}}
                    title="Mr Akshay Poddar"
                    className= {selectedIndex === 5 ? styles.leader_media_active : styles.leader_media}
                    image="/assets/images/umesh.png"
                />
            </Grid>
            <CardContent sx={{padding:"1rem 0px 0.5rem 0px", margin: isMobile? "0 1rem": "" }}>
            <Typography variant="body2" component={"p"} className={`${styles.leader_card_title} ${selectedIndex === 5 ? styles.fade_title : ''}`}>Mr. Umesh Sood</Typography>
            <Typography variant="body2" component={"p"} className={`${styles.leader_card_desig} ${selectedIndex === 5 ? styles.fade_title : ''}`}>Chief Executive Officer</Typography>
            </CardContent>
        </Card>
        </>
    ]


    return(
        <Grid className= {styles.leadership_container} sx={{paddingBottom:"120px"}}>
            <Grid className={styles.leadership_subcontainer}>
                <Typography variant="h2" component={"h2"} className={`${styles.leader_title} ${styles.leader_bottom}`}
                    sx={{paddingLeft: isMobile ? "18px": "0px", paddingRight: isMobile ? "18px": "0px"}}
                >
                    Board of Directors
                </Typography>
                <Grid className="board-slider" 
                    sx={{paddingLeft: isMobile ? "18px": "0px", paddingRight: isMobile ? "18px": "0px", borderBottom: "1px solid #c1c1c1"}}>
                    <Slider ref={boardRef} {...settings} >
                        {boardData}
                    </Slider>
                </Grid>
                <Grid className={styles.board_container}>
                    <Grid sx={{width: {xs:"100%", sm:"33%"}}}>
                        <Typography variant="h3" component={"h3"} className={`${styles.board_team_title}`}>
                            {boardTeamData[selectedIndex].title}
                        </Typography>
                        <Typography variant="body2" component={"p"} className={`${styles.board_team_design}`} sx={{marginBottom: "1rem"}}>
                            {boardTeamData[selectedIndex].designation}
                        </Typography>
                        <hr style={{marginBottom:"2rem"}} />
                        <Link href={boardTeamData[selectedIndex].url} target="_blank" ><img src="/assets/images/linkedin_icon.png" alt="linkedin" /></Link>
                    </Grid>
                    <Grid sx={{width: {xs:"100%", sm:"67%"}}}>
                    <Typography variant="body2" component={"p"} 
                        className={`${styles.board_team_description} ${heightBool ? styles.board_team_description_height : styles.board_team_description_unheight}`}
                     dangerouslySetInnerHTML={{__html:boardTeamData[selectedIndex].description}}   
                    />
                        {!heightBool && (selectedIndex === 0 || selectedIndex === 2 || selectedIndex === 3) && <Grid sx={{display:'flex', justifyContent:'center', marginTop:'20px'}}> 
                            <Button variant="text" onClick={() => {setHeightBool(true)}} sx={{textTransform: "unset"}}>Read more <ExpandMoreIcon /> </Button>
                            </Grid>
                        }
                        {heightBool && (selectedIndex === 0 || selectedIndex === 2 || selectedIndex === 3) && <Grid sx={{display:'flex', justifyContent:'center', marginTop:'20px'}}> 
                            <Button variant="text" onClick={() => {setHeightBool(false)}} sx={{textTransform: "unset"}}>Read less <ExpandLessIcon /> </Button>
                            </Grid>
                        }

                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )

}

export default Board;

