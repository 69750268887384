import React from 'react';
import Grid from '@mui/material/Grid2';
import { Typography } from '@mui/material';
import styles from './projectGallery.module.css';
import { isMobile } from 'react-device-detect';

const ProjectDetail = () => {

    return (
        <Grid className={styles.projectgallery_container} sx={{paddingBottom: "100px"}}>
            <Grid className={styles.projectgallery_subcontainer}>
                <Grid sx={{display:'flex', flexFlow: isMobile? "column" : "row", justifyContent:'space-between', gap: !isMobile ? '80px' : '40px'}}>
                    <Grid sx={{width: {xs:"100%", sm:"50%"}}}>
                        <Grid sx={{width:"100%", borderBottom: "1px solid #C1C1C1", display:'flex', alignItems:'flex-end', paddingBottom:"0.5rem"}}>
                            <Typography variant="h2" component={"h2"} 
                                className={styles.projectgallery_large_content} sx={{minWidth:"80px"}}>
                                75
                            </Typography>
                            <Typography variant="body2" component={"p"} 
                                className={styles.projectgallery_sub_title}>
                                Projects
                            </Typography>
                        </Grid>
                        <Grid sx={{width:"100%", borderBottom: "1px solid #C1C1C1", display:'flex', alignItems:'flex-end', paddingBottom:"0.5rem", paddingTop:"2rem"}}>
                            <Typography variant="h3" component={"h3"} 
                                className={styles.projectgallery_large_content} sx={{minWidth:"80px"}}>
                                4
                            </Typography>
                            <Typography variant="body2" component={"p"} 
                                className={styles.projectgallery_sub_title}>
                                Countries
                            </Typography>
                        </Grid>
                        <Grid sx={{width:"100%", borderBottom: "1px solid #C1C1C1", display:'flex', alignItems:'flex-end', paddingBottom:"0.5rem", paddingTop:"2rem"}}>
                            <Typography variant="h3" component={"h3"} 
                                className={styles.projectgallery_large_content} sx={{minWidth:"80px"}}>
                                4
                            </Typography>
                            <Typography variant="body2" component={"p"} 
                                className={styles.projectgallery_sub_title}>
                                Business Industries
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid sx={{width: {xs:"100%", sm:"50%"}}}>
                        <Typography variant="body2" component={"p"} className={styles.projectgallery_content} >
                        Our team’s expertise, combined with cutting-edge technologies and meticulous project management, has allowed us to execute complex projects with precision. Explore our gallery to discover how Simon India continues to set industry benchmarks, driving innovation and sustainable growth across sectors.
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ProjectDetail;
