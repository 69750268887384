import React, { useState } from 'react';
import Grid from '@mui/material/Grid2';
import { Typography, Pagination } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import styles from './projectGallery.module.css';
import { } from './projectGallery.css';
import { isMobile } from 'react-device-detect';


const allProjectData=[
    [[{
        title: "Feasibility Study for Filler Material Handling & Storage",
        contractNature: "DPR",
        location: "Goa, India",
        year: "2022",
        client: "Paradeep Phosphates Ltd.",
    },
    {
        title: "Residual Engineering services for 4th Evaporator Unit",
        contractNature: "Engineering",
        location: "Bhubaneshwar, India",
        year: "2021",
        client: "Paradeep Phosphates Ltd.",
    },
    {
        title: "PA Tank System (1 x 10,000 MT)",
        contractNature: "EPC",
        location: "Sikka, Gujarat, India",
        year: "2020",
        client: "GSFC",
    }],
    [{
        title: "PA Tank System (1 x 10,000 MT)",
        contractNature: "EPC",
        location: "Sikka, Gujarat, India",
        year: "2020",
        client: "GSFC",
    },
    {
        title: "Engineering Facilities for Gypmite Plant",
        contractNature: "Engg. Services",
        location: "Paradeep, Orissa, India",
        year: "2019",
        client: "Paradeep Phosphates Ltd.",
    },
    {
        title: "LPG Import Terminal BOP Package",
        contractNature: "EPC",
        location: "Mundra, Gujarat, India",
        year: "2019",
        client: "Adani",
    }]],
    [[{
        title: "Phosphoric Acid Storage System of 2 x 10,000 MT capacity",
        contractNature: "EPC",
        location: "Sikka Shore Terminal",
        year: "2018",
        client: "GSFC",
    },
    {
        title: "9.2 MW Waste Heat Recovery project for Cement Plant",
        contractNature: "EPC",
        location: "Rajgangpur, Odisha, India",
        year: "2018",
        client: "OCL (Dalmia Cement)",
    },
    {
        title: "50,000 MTPY Caprolactam Distillation Facility",
        contractNature: "EPC",
        location: "Vadodara, Gujarat, India",
        year: "2018",
        client: "GSFC",
    }],
    [{
        title: "Sugar Godown, Molasses Tank & Sugar Bag Handling System (SBH System)",
        contractNature: "EPC",
        location: "Lakhimpur, UP, India",
        year: "2018",
        client: "Gobind Sugar",
    },
    {
        title: "Retrofit of existing Sulphuric Acid plant",
        contractNature: "Paradeep , Orissa, India",
        location: "Paradeep, Orissa, India",
        year: "2018",
        client: "Paradeep Phosphates Ltd.",
    },
    {
        title: "200 TPD Chlor-Alkali Plant",
        contractNature: "EPCM",
        location: "Qatar",
        year: "2018",
        client: "KLJ",
    }]]
]
const chemicalProjectData=[
    [[{
        title: "PA Tank System (1 x 10,000 MT)",
        contractNature: "EPC",
        location: "Sikka, Gujarat, India",
        year: "2020",
        client: "GSFC",
    },
    {
        title: "Engineering Facilities for Gypmite Plant",
        contractNature: "Engg. Services",
        location: "Paradeep, Orissa, India",
        year: "2019",
        client: "Paradeep Phosphates Ltd.",
    },
    {
        title: "50,000 MTPY Caprolactam Distillation Facility",
        contractNature: "EPC",
        location: "Vadodara, Gujarat, India",
        year: "2018",
        client: "GSFC",
    }],
    [{
        title: "Phosphoric Acid Storage System of 2 x 10,000 MT capacity",
        contractNature: "EPC",
        location: "Sikka Shore Terminal",
        year: "2018",
        client: "GSFC",
    },
    {
        title: "Sugar Godown, Molasses Tank & Sugar Bag Handling System (SBH System)",
        contractNature: "EPC",
        location: "Lakhimpur, UP, India",
        year: "2018",
        client: "Gobind Sugar",
    },
    {
        title: "Retrofit of existing Sulphuric Acid plant",
        contractNature: "EPC",
        location: "Paradeep , Orissa, India",
        year: "2018",
        client: "Paradeep Phosphates Ltd.",
    }]],
    [[{
        title: "200 TPD Chlor-Alkali Plant",
        contractNature: "EPCM",
        location: "Qatar",
        year: "2018",
        client: "KLJ",
    },
    {
        title: "2000 MTPD Sulphuric Acid Plant with HRS & Power Plant Facility",
        contractNature: "EPC",
        location: "Paradeep, Orissa, India",
        year: "2015",
        client: "Paradeep Phosphates Ltd.",
    },
    {
        title: "Phosphoric Acid Storage System of 5,000 MT capacity",
        contractNature: "EPC",
        location: "Paradeep, Orissa, India",
        year: "2013",
        client: "Paradeep Phosphates Ltd.",
    }],
    [{
        title: "Sulphuric Acid Storage System of 10,000 MT capacity",
        contractNature: "EPC",
        location: "Paradeep, Orissa, India",
        year: "2013",
        client: "Paradeep Phosphates Ltd.",
    },
    {
        title: "HRS system",
        contractNature: "EPC",
        location: "Paradeep, Orissa, India",
        year: "2013",
        client: "IFFCO, Paradeep",
    },
    {
        title: "New Final Absorption Tower and associated work in existing Sulphuric Acid Plant",
        contractNature: "EPC",
        location: "Chanderiya, Rajasthan, India",
        year: "2011",
        client: "Hindustan Zinc Limited",
    }]]
]

const fertilizerProjectData=[
    [[{
        title: "Feasibility Study for Filler Material Handling & Storage",
        contractNature: "DPR",
        location: "Goa, India",
        year: "2022",
        client: "Paradeep Phosphates Ltd.",
    },
    {
        title: "Residual Engineering services for 4th Evaporator Unit",
        contractNature: "Engineering",
        location: "Bhubaneshwar, India",
        year: "2021",
        client: "Paradeep Phosphates Ltd.",
    },
    {
        title: "PA Tank System (1 x 10,000 MT)",
        contractNature: "EPC",
        location: "Sikka, Gujarat, India",
        year: "2020",
        client: "GSFC",
    }],
    [{
        title: "Design & Detail Engineering of New Structural Trolley for Ammonia Unloading System",
        contractNature: "Design & Engg.",
        location: "Paradeep , Orissa , India",
        year: "2016",
        client: "Paradeep Phosphates Ltd.",
    },
    {
        title: "Estimation of CAPEX for 10,000 TPA Aluminum Fluoride Plant",
        contractNature: "Engg. Services",
        location: "Paradeep , Orissa , India",
        year: "2015",
        client: "Paradeep Phosphates Ltd.",
    },
    {
        title: "600 MTPD Powder & Granulated SSP Plant",
        contractNature: "EPC",
        location: "Mahad, Maharashtra , India",
        year: "2014",
        client: "Zuari Fertilizers and Chemicals",
    }]],
    [[{
        title: "600 MTPD Powder SSP and 2x300 MTPD Granulated SSP Plant ",
        contractNature: "EPC",
        location: "Gadepan, Rajasthan, India",
        year: "2013",
        client: "Chambal Fertilisers & Chemicals",
    },
    {
        title: "Gypsum Storage Shed for Railway Loading",
        contractNature: "EPC",
        location: "Paradeep, Odisha, India",
        year: "2011",
        client: "Paradeep Phosphates Ltd.",
    },
    {
        title: "CO Based Diversification Products Study",
        contractNature: "Feasibility Study",
        location: "Thal, Maharashtra, India",
        year: "2003",
        client: "RCF",
    }],
    [{
        title: "Urea Plant Revamp - Ammonia Preheater",
        contractNature: "Detail Engg. Service",
        location: "Gadepan, Rajasthan, India",
        year: "2019",
        client: "Chambal Fertilisers & Chemicals",
    }]
    ]
]

const refineryProjectData=[
    [[{
        title: "Crude Oil Storage Facility",
        contractNature: "EPCM",
        location: "Vizag, A. P., India",
        year: "2007",
        client: "Hindustan Petroleum Corporation",
    },
    {
        title: "Cross Country Pipeline for transportation of White Oil",
        contractNature: "EPC",
        location: "Goa, India",
        year: "2003",
        client: "Zuari Indian Oil Tanking Limited",
    },
    {
        title: "Technical Due Diligence of Engineers India Limited",
        contractNature: "DueDiligence Service",
        location: "India",
        year: "2002",
        client: "ONGC",
    }],
    [{
        title: "LPG Import Terminal BOP Package",
        contractNature: "EPC",
        location: "Mundra, Gujarat, India",
        year: "2019",
        client: "Adani",
    },
    {
        title: "PNG Pipeline Network",
        contractNature: "Detail Engg. Services",
        location: "Chanderiya, Rajasthan, India",
        year: "2012",
        client: "Hindustan Zinc Limited",
    },
    {
        title: "Project Cost Estimation for Petroleum Products Distribution Terminal",
        contractNature: "Basic Engg. & Cost Estimation",
        location: "India",
        year: "1999",
        client: "Caltex India Limited",
    }]]
]
const cementProjectData=[
    [[{
        title: "Preliminary Engg . & Preparation of EPC Tender Document for a 10,000 TPD Grass root Cement Plant",
        contractNature: "Engg. Services",
        location: "Gulbarga, Karnataka, India",
        year: "2011",
        client: "Italcementi, Italy",
    },
    {
        title: "Expansion of Cement Plant Capacity by 1.2 MTPA",
        contractNature: "Construction Supervision Service",
        location: "Yerraguntla, A.P., India",
        year: "2000",
        client: "Zuari Cement",
    },
    {
        title: "23 MW Captive Power Plant",
        contractNature: "EPC",
        location: "Paradeep, Odisha, India",
        year: "2015",
        client: "Paradeep Phosphates Ltd",
    }],
    [{
        title: "9.2 MW Waste Heat Recovery project for Cement Plant",
        contractNature: "EPC",
        location: "Rajgangpur, Odisha, India",
        year: "2018",
        client: "OCL (Dalmia Cement)",
    }]
    ]
]


const ProjectAll = () => {

    const [age, setAge] = useState("refinery");

    const [currentIndex, setCurrentIndex] = useState(0);    
    const [currentProject, setCurrentProject] = useState(refineryProjectData)

    const handleCurrentIndex = (event, value) =>{
        if(currentIndex === value-1)return ;
        setCurrentIndex(value-1);
    }

    const handleChange = (event) => {
        // console.log(event.target.value);
        setAge(event.target.value);
        // document.getElementById("news_card")[0].empty();
        setCurrentIndex(0);
        switch(event.target.value){
            case "all":
                setCurrentProject(allProjectData);
                break;
            case "chemical":
                setCurrentProject(chemicalProjectData);
                break;
            case "fertilizers":
                setCurrentProject(fertilizerProjectData);
                break;
            case "refinery":
                setCurrentProject(refineryProjectData);
                break;
            case "cement":
                setCurrentProject(cementProjectData);
                break;
            default:
                setCurrentProject(allProjectData);
        } 
    }

    return (
        <Grid className={`${styles.projectgallery_container}`} 
            sx={{paddingBottom: "120px", paddingTop: "120px"}}>
            <Grid className={styles.projectgallery_subcontainer}>
                <Grid sx={{display:'flex', borderBottom: '1px solid #C1C1C1', alignItems:'center'}}>
                    <Grid sx={{width:{xs:"100%", sm:"100%"}}}>
                        <Typography variant='h3' component={"h3"} className={styles.projectgallery_title}>
                            Projects
                        </Typography>
                    </Grid>
                    <Grid sx={{width:{xs:"100%", sm:"100%"}}}>
                        <Grid sx={{display:'flex', justifyContent:'flex-end'}}>
                            <Grid>
                            <Typography variant='body2' component={"p"} 
                                className={styles.projectindusty_content} sx={{color: "#2182FF !important"}}>
                                Industry
                            </Typography>
                            <FormControl sx={{ m: 0, minWidth: 240, border:0 }} size="small">
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    value={age}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    displayEmpty
                                    sx={{border:0}}
                                    onChange={handleChange}
                                >
                                    <MenuItem value="all" className={styles.projectindusty_content}>
                                    <em>All Industries</em>
                                    </MenuItem>
                                    <MenuItem value={"chemical"} className={styles.projectindusty_content}>Chemical</MenuItem>
                                    <MenuItem value={"fertilizers"} className={styles.projectindusty_content}>Fertilizers</MenuItem>
                                    <MenuItem value={"refinery"} className={styles.projectindusty_content}>Refinery & Petrochemical</MenuItem>
                                    <MenuItem value={"cement"} className={styles.projectindusty_content}>Cement & Power</MenuItem>
                                </Select>
                            </FormControl>
                            </Grid>
                        </Grid>
                        
                    </Grid>
                </Grid>
                <Grid id="news_card">

                    {currentProject.length>0 &&
                        currentProject[currentIndex].map((items, itemIndex) => (
                            <Grid className={styles.news_card}>
                                {items.map((item, index) => (
                                    <Grid key={`project_${itemIndex}_${index}`} className={styles.news_card_container}>
                                        <Grid sx={{borderBottom:"1px solid #c1c1c1", paddingBottom: '1rem', minHeight:"105px"}}>
                                            <Typography variant="h3" component={"h3"} className={styles.projectall_title}>
                                                {item.title}
                                            </Typography>
                                        </Grid>
                                        <Typography variant="body2" component={"p"} 
                                            className={styles.projectall_content} sx={{marginTop:"1rem"}}>
                                            Contract - <span className={styles.projectall_type_color} style={{fontWeight: 600}}>{item.contractNature}</span>
                                        </Typography>
                                        <Typography variant="body2" component={"p"} sx={{marginTop:"1rem"}} 
                                            className={`${styles.projectall_content}`} >
                                            Location - <span className={styles.projectall_type_color} style={{fontWeight: 600}}>{item.location}</span>
                                        </Typography>
                                        <Typography variant="body2" component={"p"} sx={{marginTop:"0.4rem"}} 
                                            className={`${styles.projectall_content}`}>
                                            Year - <span className={styles.projectall_type_color} style={{fontWeight: 600}}>{item.year}</span>
                                        </Typography>
                                        <Typography variant="body2" component={"p"} sx={{marginTop:"0.4rem"}} className={`${styles.projectall_content}`}>
                                            Client - <span className={styles.projectall_type_color} style={{fontWeight: 600}}>{item.client}</span>
                                        </Typography>
                                        <Grid sx={{height:"10px", marginTop:'2rem', backgroundColor:"#B0D22D", width:"100%"}}>&nbsp;</Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        ))
                    }
                </Grid>
                {/* <Grid className={styles.news_card}>
                    <Grid className={styles.news_card_container}>
                        <Grid sx={{borderBottom:"1px solid #c1c1c1", paddingBottom: '1rem'}}>
                            <Typography variant="h3" component={"h3"} className={styles.projectall_title}>
                                Chemanol Limited
                            </Typography>
                        </Grid>
                        <Typography variant="body2" component={"p"} className={styles.projectall_content} sx={{marginTop:"1rem"}}>
                            Grass root Methanol Complex including downstream units like MA/DMF and Penta.
                        </Typography>
                        <Typography variant="body2" component={"p"} sx={{marginTop:"1rem"}} className={`${styles.projectall_content} ${styles.projectall_type_color}`}>
                            PMC Services
                        </Typography>
                        <Typography variant="body2" component={"p"} sx={{marginTop:"0.4rem"}} className={`${styles.projectall_content}`}>
                            Location - <span className={styles.projectall_type_color}>Jubail, Saudi Arabia</span>
                        </Typography>
                        <Typography variant="body2" component={"p"} sx={{marginTop:"0.4rem"}} className={`${styles.projectall_content}`}>
                            Year - <span className={styles.projectall_type_color}>2009</span>
                        </Typography>
                        <Grid sx={{height:"10px", marginTop:'2rem', backgroundColor:"#B0D22D", width:"100%"}}>&nbsp;</Grid>
                    </Grid>
                    <Grid className={styles.news_card_container}>
                        <Grid sx={{borderBottom:"1px solid #c1c1c1", paddingBottom: '1rem'}}>
                            <Typography variant="h3" component={"h3"} className={styles.projectall_title}>
                                Chemanol Limited
                            </Typography>
                        </Grid>
                        <Typography variant="body2" component={"p"} className={styles.projectall_content} sx={{marginTop:"1rem"}}>
                            Grass root Methanol Complex including downstream units like MA/DMF and Penta.
                        </Typography>
                        <Typography variant="body2" component={"p"} sx={{marginTop:"1rem"}} className={`${styles.projectall_content} ${styles.projectall_type_color}`}>
                            PMC Services
                        </Typography>
                        <Typography variant="body2" component={"p"} sx={{marginTop:"0.4rem"}} className={`${styles.projectall_content}`}>
                            Location - <span className={styles.projectall_type_color}>Jubail, Saudi Arabia</span>
                        </Typography>
                        <Typography variant="body2" component={"p"} sx={{marginTop:"0.4rem"}} className={`${styles.projectall_content}`}>
                            Year - <span className={styles.projectall_type_color}>2009</span>
                        </Typography>
                        <Grid sx={{height:"10px", marginTop:'2rem', backgroundColor:"#B0D22D", width:"100%"}}>&nbsp;</Grid>
                    </Grid>
                    <Grid className={styles.news_card_container}>
                        <Grid sx={{borderBottom:"1px solid #c1c1c1", paddingBottom: '1rem'}}>
                            <Typography variant="h3" component={"h3"} className={styles.projectall_title}>
                                Chemanol Limited
                            </Typography>
                        </Grid>
                        <Typography variant="body2" component={"p"} className={styles.projectall_content} sx={{marginTop:"1rem"}}>
                            Grass root Methanol Complex including downstream units like MA/DMF and Penta.
                        </Typography>
                        <Typography variant="body2" component={"p"} sx={{marginTop:"1rem"}} className={`${styles.projectall_content} ${styles.projectall_type_color}`}>
                            PMC Services
                        </Typography>
                        <Typography variant="body2" component={"p"} sx={{marginTop:"0.4rem"}} className={`${styles.projectall_content}`}>
                            Location - <span className={styles.projectall_type_color}>Jubail, Saudi Arabia</span>
                        </Typography>
                        <Typography variant="body2" component={"p"} sx={{marginTop:"0.4rem"}} className={`${styles.projectall_content}`}>
                            Year - <span className={styles.projectall_type_color}>2009</span>
                        </Typography>
                        <Grid sx={{height:"10px", marginTop:'2rem', backgroundColor:"#B0D22D", width:"100%"}}>&nbsp;</Grid>
                    </Grid>
                </Grid> */}
                <Grid sx={{width: "100%", margin:"80px auto"}}>
                    <Pagination onChange={handleCurrentIndex} count={!isMobile? currentProject.length: 5} shape="rounded" />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ProjectAll;
