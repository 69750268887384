import React from 'react';
import Grid from '@mui/material/Grid2';
import PageBanner from '../../components/pageBanner';
// import AdvantageC from '../../components/advantageC';
import PageContent from '../../components/pageContent';
import AboutExplore from '../aboutus/aboutExplore';
import FaqContainer from './faqContainer';
import { isMobile } from 'react-device-detect';


const FaqPage = () => {

    const bannerPath = [
        {title: 'Home', url: '/'},
        {title: 'Projects', url: ''},
        {title: "FAQs", url: ''}
    ]


return (
    <Grid sx={{width: "100%"}}>
        <PageBanner title="FAQs" bannerimage="faq_banner.jpg" 
            bannerPath={bannerPath}  />
        <PageContent 
           details="Welcome to the Simon India FAQ page, where you'll find answers to common questions about our services, expertise, and approach to project execution. Whether you're curious about our experience in international markets, compliance strategies, or how we collaborate with clients, this section provides valuable insights into how we work." />
        <FaqContainer marginTop="0px"/>
        <AboutExplore />
        <FaqContainer marginTop= {!isMobile ? "120px" : "80px"} />
    </Grid>
)

}

export default FaqPage;