import React from 'react';
import Grid from '@mui/material/Grid2';
import { Typography } from '@mui/material';
import styles from './aboutus.module.css';


const AboutBrand = () => {

    return  (
        <Grid className={styles.aboutbrand_container}>
            <Grid className={styles.aboutbrand_subcontainer}>
            <Typography variant='h2' component={"h2"} className={`${styles.about_subtitle} ${styles.colorWhite}`} >What Our Brand Mark Symbolizes</Typography>
                <Grid className={styles.aboutbrand_card}>
                    <Grid sx={{width: {xs: "100%", sm: '70%'}}}>
                        
                        <Typography variant="body2" component={"p"}className={`${styles.about_content} ${styles.colorWhite}`}>
                            Simon India's brand symbol embodies our unwavering determination to grow—both as an organization and in our contributions to the environment and society. It reflects our commitment to sustainability, continuous learning, fostering a circular economy, and fostering strong, lasting relationships.
                        </Typography>
                        <Grid className={styles.aboutbrand_card_button}>
                            <Grid sx={{width: {xs:"100%", sm:'50%'}}} >
                                <div className= {styles.brandButton}>
                                    <Typography variant="body2" component={"p"}className={`${styles.about_content} ${styles.colorWhite}`} sx={{fontSize: '1.25rem'}}>
                                        Sustainability
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid sx={{width: {xs:"100%", sm:'50%'}}} >
                                <div className= {styles.brandButton}>
                                    <Typography variant="body2" component={"p"}className={`${styles.about_content} ${styles.colorWhite}`} sx={{fontSize: '1.25rem'}}>
                                        Continuous learning
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid  className={styles.aboutbrand_card_button} sx={{marginTop:'1rem'}}>
                            <Grid sx={{width: {xs:"100%", sm:'50%'}}} >
                                <div className= {styles.brandButton}>
                                    <Typography variant="body2" component={"p"}className={`${styles.about_content} ${styles.colorWhite}`} sx={{fontSize: '1.25rem'}}>
                                        Circular economy
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid sx={{width: {xs:"100%", sm:'50%'}}} >
                                <div className= {styles.brandButton}>
                                    <Typography variant="body2" component={"p"}className={`${styles.about_content} ${styles.colorWhite}`} sx={{fontSize: '1.25rem'}}>
                                        Relationships
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                        
                    </Grid>
                    <Grid sx={{width: {xs: "100%", sm: '30%'}}}>
                        <img src="/assets/images/brandimg.png" alt="" />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )


}

export default AboutBrand;