import React from 'react';
import Grid from '@mui/material/Grid2';

import styles from './homepossible.module.css';
import { Typography } from '@mui/material';

const HomePossible = () => {

    return (
        <Grid className={styles.homepossible_container}>
            <Grid className={styles.redefined_maincontainer}>
            <Typography variant='h2' component={"h2"} className= {styles.redefined_heading}>
                Redefining what’s possible
            </Typography>
            <Typography variant='' component={"p"} className={styles.redefined_text}>
                With our deep legacy of engg excellence
            </Typography>

            <Grid container className={styles.redefined_container}>
                <Grid item sx={{width: {xs:"50%", sm:"25%"}}}>
                    <Typography variant='h3' component={'h3'} className= {styles.redefined_bigtext} >
                        27+
                    </Typography>
                    <div style={{width: '100%', borderTop: '1px solid #FFFFFF'}}>
                    <Typography variant='p' component={'p'} className= {styles.redefined_bigtext_subheading} >
                        years of legacy
                    </Typography>
                    </div>
                </Grid>
                <Grid sx={{width: {xs:"50%", sm:"25%"}}}>
                    <Typography variant='h3' component={'h3'} className= {styles.redefined_bigtext} >
                        60+
                    </Typography>
                    <div style={{width: '100%', borderTop: '1px solid #FFFFFF'}}>
                        <Typography variant='p' component={'p'} className= {styles.redefined_bigtext_subheading} >
                            global clients
                        </Typography>
                    </div>
                </Grid>
                <Grid sx={{width: {xs:"50%", sm:"25%"}}}>
                    <Typography variant='h3' component={'h3'} className= {styles.redefined_bigtext} >
                        75+
                    </Typography>
                    <div style={{width: '100%', borderTop: '1px solid #FFFFFF'}}>
                    <Typography variant='p' component={'p'} className= {styles.redefined_bigtext_subheading} >
                        major projects delivered
                    </Typography>
                    </div>
                </Grid>
                <Grid sx={{width: {xs:"50%", sm:"25%"}}}>
                    <div className={styles.redefined_image}>
                        <img src='/assets/images/redefine-img.png' alt="redefine" />
                        {/* <img src='/assets/images/redefine-img.png' alt="redefine" /> */}
                    </div>
                </Grid>
                
            </Grid>
        </Grid>
    </Grid>

    )
}

export default HomePossible;