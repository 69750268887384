import React from 'react';
import Grid from '@mui/material/Grid2'

import PageBanner from '../../../components/pageBanner';
import PageContent from '../../../components/pageContent';
import ApplyForm from './applyForm';
import AdvantageC from '../../../components/advantageC';

const ApplyJob = () => {

    const bannerPath = [
        {title: 'Home', url: '/'},
        {title: 'Join us', url: '/'},
        {title: 'Apply', url: ''}
    ]

    return (
        <Grid sx={{display: 'flex', flexFlow:'column', width: '100%'}}>
            <PageBanner title= "Apply" bannerimage="apply_banner.jpg" bannerPath={bannerPath} />
            <PageContent details="Interested in becoming a valued partner of Simon India? Fill out the form below with your details, and let’s explore how we can collaborate on exciting projects that drive innovation and success across industries."  />
            <ApplyForm />
            <AdvantageC />
        </Grid>
    )
}

export default ApplyJob;