import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid2';
import { Link, Typography } from '@mui/material';
import Slider from 'react-slick';
import { East, West } from '@mui/icons-material';


import styles from './exploreProject.module.css'
import { isMobile } from 'react-device-detect';


const ExploreProject = () => {
    const navigate = useNavigate ()
    const [currentIndex, setCurrentIndex] = useState(0);

    const projectDetailsData = [
        {
            id: 0,
            backgroundImage: "/assets/images/projects/project_1.jpg",
            projectName: "Anhydrous Aluminum Chloride Plant.",
            projectDest: "Ernakulum, Kerala",
            projectDetails: "Detailed Project Report for The Anhydrous Aluminum Chloride Plant."
        },
        {
            id: 0,
            backgroundImage: "/assets/images/projects/project_2.jpg",
            projectName: "23 MW Power Captive Plant",
            projectDest: "Paradeep, Odisha",
            projectDetails: "Lump sum turn key execution of 23 MW Captive Power Plant. Heat recovery from Sulphur burning gases"
        },
        {
            id: 0,
            backgroundImage: "/assets/images/projects/project_3.jpg",
            projectName: "Sea Water Cooling Tower",
            projectDest: "Jubail, Saudi Arabia",
            projectDetails: "Engineering, procurement and construction management of sea water cooling tower"
        },
        {
            id: 0,
            backgroundImage: "/assets/images/projects/project_4.jpg",
            projectName: "Gas Cleaning System",
            projectDest: "Chittorgarh , Rajasthan",
            projectDetails: "EPC of gas cleaning plant - Treatment of Dust laden SO2 Gas from Smelter passing through Venturi Scrubber, Packed Bed Cooler, ID FAN, large size Ducting, Stack & Associated Equipment "
        },
        {
            id: 0,
            backgroundImage: "/assets/images/projects/project_5.jpg",
            projectName: "Caprolactum Distillation Project",
            projectDest: "Vadodara, Gujarat",
            projectDetails: "Engineering, Procurement, Construction & Commissioning (EPC) of 50,000 MTPY Caprolactam Distillation Unit"
        },
        {
            id: 0,
            backgroundImage: "/assets/images/projects/project_6.jpg",
            projectName: "Cross Country Pipeline Project",
            projectDest: "Goa",
            projectDetails: "Lump sum turnkey execution for transportation of oil pipeline work"
        }
    ]
    const NextArrow = props => {
        const { className, style, onClick } = props;
        return <East
        className={className}
        style={{ ...style, display: "block", right:"-5%", background: "transparent", color: "rgba(33, 130, 255, 1)" }}
        onClick={onClick}
      />
    }
    const PrevArrow = props => {
        const { className, style, onClick } = props;
        return <West
        className={className}
        style={{ ...style, display: "block", left:"-5%", background: "transparent", color: "rgba(33, 130, 255, 1)" }}
        onClick={onClick}
      />
    }

    const settings = {
        dots: false,
        arrows: true,
        dotsClass: 'slick-dots',
        className: 'center',
        centerMode: false,
        infinite: false,
        centerPadding: '30px',
        slidesToShow: 3,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        slidesToScroll: Math.min(1),
        swipeToSlide: true,
        speed: 500,
        responsive: [
            { 
            breakpoint: 1025,
                setting: {

                }
            },
            {
                breakpoint: 450,
                settings: {
                    dots: true,
                    arrows: false,
                    centerMode: true,
                    infinite: true,
                    slidesToShow: 1,
                }
            }
        ]
    }

    const handleProject = (index) => {
        setCurrentIndex(index);
    }

    
    const projectData = [
        <>
            <div onClick={() => handleProject(0)} style={{cursor:"pointer"}}>
                <img style={{width: isMobile ? 252: "auto"}} src='/assets/images/projects/project1_thumb.png' alt='' />
                {currentIndex === 0 &&
                    <Grid sx={{position:"relative", height: isMobile ?"146px" :"195px", width: isMobile ? 252: "348px",  marginTop: isMobile? "-146px":"-195px"}}>
                        <Grid sx={{position:"absolute", top:0, width: isMobile ? 252: "350px", height: isMobile ? 146: "195px", borderRadius:"5px", backgroundColor:"#00258A"}}>&nbsp;</Grid>
                        <Typography variant='body2' component={"p"} className={styles.project_dest}>{projectDetailsData[0].projectDest}</Typography>
                        <Typography variant='body2' component={"p"} className={styles.project_card_title}>{projectDetailsData[0].projectName}</Typography>
                    </Grid>
                }
                
            </div>
        </>,
        <>
            <div onClick={() => handleProject(1)} style={{cursor:"pointer"}}>
                <img style={{width: isMobile ? "252px": "auto"}}src='/assets/images/projects/project2_thumb.png' alt='' />
                {currentIndex === 1 &&
                    <Grid sx={{position:"relative", height: isMobile ?"146px" :"195px", width: isMobile ? 252: "348px", marginTop: isMobile? "-146px":"-195px"}}>
                        <Grid sx={{position:"absolute", top:0, width: isMobile ? 252: "350px", height: isMobile ? 146: "195px", borderRadius:"5px", backgroundColor:"#00258A"}}>&nbsp;</Grid>
                        <Typography variant='body2' component={"p"} className={styles.project_dest}>{projectDetailsData[1].projectDest}</Typography>
                        <Typography variant='body2' component={"p"} className={styles.project_card_title}>{projectDetailsData[1].projectName}</Typography>
                    </Grid>
                }
            </div>
        </>,
        <>
            <div onClick={() => handleProject(2)} style={{cursor:"pointer"}}>
                <img style={{width: isMobile ? "252px": "auto"}}src='/assets/images/projects/project3_thumb.png' alt='' />
                {currentIndex === 2 &&
                    <Grid sx={{position:"relative", height: isMobile ?"146px" :"195px", width: isMobile ? 252: "348px",  marginTop: isMobile? "-146px":"-195px"}}>
                        <Grid sx={{position:"absolute", top:0, width: isMobile ? 252: "350px", height: isMobile ? 146: "195px", borderRadius:"5px", backgroundColor:"#00258A"}}>&nbsp;</Grid>
                        <Typography variant='body2' component={"p"} className={styles.project_dest}>{projectDetailsData[2].projectDest}</Typography>
                        <Typography variant='body2' component={"p"} className={styles.project_card_title}>{projectDetailsData[2].projectName}</Typography>
                    </Grid>
                }
            </div>
        </>,
        <>
            <div onClick={() => handleProject(3)}>
                <img style={{width: isMobile ? "252px": "auto"}} src='/assets/images/projects/project4_thumb.png' alt='' />
                {currentIndex === 3 &&
                    <Grid sx={{position:"relative", height: isMobile ?"146px" :"195px", width: isMobile ? 252: "348px",  marginTop: isMobile? "-146px":"-195px"}}>
                        <Grid sx={{position:"absolute", top:0, width: isMobile ? 252: "350px", height: isMobile ? 146: "195px", borderRadius:"5px", backgroundColor:"#00258A"}}>&nbsp;</Grid>
                        <Typography variant='body2' component={"p"} className={styles.project_dest}>{projectDetailsData[3].projectDest}</Typography>
                        <Typography variant='body2' component={"p"} className={styles.project_card_title}>{projectDetailsData[3].projectName}</Typography>
                    </Grid>
                }
            </div>
        </>,
        <>
            <div onClick={() => handleProject(4)}>
                <img style={{width: isMobile ? "252px": "auto"}} src='/assets/images/projects/project5_thumb.png' alt='' />
                {currentIndex === 4 &&
                    <Grid sx={{position:"relative", height: isMobile ?"146px" :"195px", width: isMobile ? 252: "348px",  marginTop: isMobile? "-146px":"-195px"}}>
                        <Grid sx={{position:"absolute", top:0, width: isMobile ? 252: "350px", height: isMobile ? 146: "195px", borderRadius:"5px", backgroundColor:"#00258A"}}>&nbsp;</Grid>
                        <Typography variant='body2' component={"p"} className={styles.project_dest}>{projectDetailsData[4].projectDest}</Typography>
                        <Typography variant='body2' component={"p"} className={styles.project_card_title}>{projectDetailsData[4].projectName}</Typography>
                    </Grid>
                }
            </div>
        </>,
        <>
            <div onClick={() => handleProject(5)}>
                <img style={{width: isMobile ? "252px": "auto"}} src='/assets/images/projects/project6_thumb.png' alt='' />
                {currentIndex === 5 &&
                    <Grid sx={{position:"relative", height: isMobile ?"146px" :"195px", width: isMobile ? 252: "348px",  marginTop: isMobile? "-146px":"-195px"}}>
                        <Grid sx={{position:"absolute", top:0, width: isMobile ? 252: "350px", height: isMobile ? 146: "195px", borderRadius:"5px", backgroundColor:"#00258A"}}>&nbsp;</Grid>
                        <Typography variant='body2' component={"p"} className={styles.project_dest}>{projectDetailsData[5].projectDest}</Typography>
                        <Typography variant='body2' component={"p"} className={styles.project_card_title}>{projectDetailsData[5].projectName}</Typography>
                    </Grid>
                }
            </div>
        </>
    ]



    return (
        <Grid className={styles.explore_container} sx={{paddingTop: '100px', paddingBottom: "180px"}}>
        <Grid className={styles.explore_subcontainer}>
            <Typography variant="h3" component={"h3"} className={styles.explore_title} 
                sx={{width:'100%', textAlign:'center', marginBottom: "40px"}}>Explore Our Projects</Typography>
            <Grid sx={{background:`url(${projectDetailsData[currentIndex].backgroundImage}) no-repeat`}} className={styles.project_container}>
                <Grid sx={{margin:"-18px", padding:"30px 18px", backgroundColor: "rgba(255, 255, 255, 0.2)"}}>
                    <Typography variant="body2" component={"p"} className={styles.project_content} > {projectDetailsData[currentIndex].projectDest} </Typography>
                    <Typography variant="h4" component={"h4"} className={styles.explore_sub_title} sx={{marginBottom: '1rem'}} > {projectDetailsData[currentIndex].projectName}</Typography>
                    <Typography variant="body2" component={"p"} className={styles.project_content} > {projectDetailsData[currentIndex].projectDetails} </Typography>
                </Grid>
            </Grid>
            <Grid sx={{width: "100%", marginTop:'1rem'}}>
                <Slider {...settings}>
                    {projectData}
                </Slider>
                <Grid sx={{width: '100%', display:'flex', justifyContent:'flex-end'}}>
                    <Link href='/project-gallery' className={styles.project_button}>View all <East /></Link>
                </Grid>
            </Grid>
        </Grid>

    </Grid>)
}

export default ExploreProject;