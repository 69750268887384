import React, {useRef} from 'react';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';

import {} from './homeproject.css'
import styles from './homeproject.module.css';
import Slider from 'react-slick';
import { East, West } from '@mui/icons-material';

const HomeProjects = () => {

    const sliderRef = useRef();

    const NextArrow = props => {
        const { className, style, onClick } = props;
        return <East
        className={className}
        style={{ ...style, display: "block", right:"-8%", background: "transparent", color: "rgba(33, 130, 255, 1)" }}
        onClick={onClick}
      />
    }
    const PrevArrow = props => {
        const { className, style, onClick } = props;
        return <West
        className={className}
        style={{ ...style, display: "block", left:"-8%", background: "transparent", color: "rgba(33, 130, 255, 1)" }}
        onClick={onClick}
      />
    }

    const settings= {
        dots: true,
        dotsClass: 'slick-dots',
        className: 'center',
        centerMode: false,
        infinite: true,
        centerPadding: '30px',
        slidesToShow: Math.min(2, 1),
        slidesToScroll: Math.min(1),
        swipeToSlide: false,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        speed: 500,
        responsive: [
           { 
            breakpoint: 1025,
            settings: {

                }
            },
            { 
            breakpoint: 450,
            settings: {
                    dots: true,
                    arrows: false,
                    slidesToShow: 1,
                    swipeToSlide: true,
                    autoplay: true,
                }
            }
        ]
    }

    const projectData = [
        <>
            <Typography variant='body2' component={"h3"} className={styles.project_title}>Chlor Alkali Plant Project</Typography>
            <Grid className={styles.project_container}>
                <img src="/assets/images/projects/project_chlor.jpg" alt="project 1" />
                <Grid style={{marginRight:"1em"}}>
                    <Typography variant='body2' component={"p"} className={styles.project_details_content}>Detailed engineering & project management services for Caustic Soda plant</Typography>
                    <div className={styles.project_details_container}>
                        <div>
                            <Typography variant='body2' component={"p"} className={styles.project_details}>Client</Typography>
                            <Typography variant='body2' component={"p"} className={styles.project_details_content}>KLJ, Qatar</Typography>
                        </div>
                        <div>
                            <Typography variant='body2' component={"p"} className={styles.project_details}>Location</Typography>
                            <Typography variant='body2' component={"p"} className={styles.project_details_content}>Qatar</Typography>
                        </div>
                        <div>
                            <Typography variant='body2' component={"p"} className={styles.project_details}>Capacity</Typography>
                            <Typography variant='body2' component={"p"} className={styles.project_details_content}>200 MTPD</Typography>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </>,
        <>
            <Typography variant='body2' component={"h3"} className={styles.project_title}>Methanol Complex Project</Typography>
            <Grid className={styles.project_container}>
                <img src="/assets/images/projects/project_methanol.jpg" alt="project 1" />
                <Grid>
                    <Typography variant='body2' component={"p"} className={styles.project_details_content}>Project Management Consultancy (PMC) services for methanol complex</Typography>
                    <div className={styles.project_details_container}>
                        <div>
                            <Typography variant='body2' component={"p"} className={styles.project_details}>Client</Typography>
                            <Typography variant='body2' component={"p"} className={styles.project_details_content}>Chemanol, Saudi Arabia</Typography>
                        </div>
                        <div>
                            <Typography variant='body2' component={"p"} className={styles.project_details}>Location</Typography>
                            <Typography variant='body2' component={"p"} className={styles.project_details_content}>Saudi Arabia</Typography>
                        </div>
                        <div>
                            <Typography variant='body2' component={"p"} className={styles.project_details}>{""}</Typography>
                            <Typography variant='body2' component={"p"} className={styles.project_details_content}>{""}</Typography>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </>,
        <>
            <Typography variant='body2' component={"h3"} className={styles.project_title}>Sulphuric Acid Project</Typography>
            <Grid className={styles.project_container}>
                <img src="/assets/images/projects/project_sulphuric.jpg" alt="project 1" />
                <Grid>
                    <Typography variant='body2' component={"p"} className={styles.project_details_content}>Engineering, Procurement and Construction of Sulphuric acid plant including 23 MW captive power plant, offsites and utilities</Typography>
                    <div className={styles.project_details_container}>
                        <div>
                            <Typography variant='body2' component={"p"} className={styles.project_details}>Client</Typography>
                            <Typography variant='body2' component={"p"} className={styles.project_details_content}>Paradeep Phosphates Limited</Typography>
                        </div>
                        <div>
                            <Typography variant='body2' component={"p"} className={styles.project_details}>Location</Typography>
                            <Typography variant='body2' component={"p"} className={styles.project_details_content}>Paradeep, Odisha</Typography>
                        </div>
                        <div>
                            <Typography variant='body2' component={"p"} className={styles.project_details}>{"Capacity"}</Typography>
                            <Typography variant='body2' component={"p"} className={styles.project_details_content}>{"2000 MTPD"}</Typography>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </>,
        <>
            <Typography variant='body2' component={"h3"} className={styles.project_title}>LPG Terminal Project</Typography>
            <Grid className={styles.project_container}>
                <img src="/assets/images/projects/project_lpg.jpg" alt="project 1" />
                <Grid>
                    <Typography variant='body2' component={"p"} className={styles.project_details_content}>LPG Propane Butane handling and storage facility</Typography>
                    <div className={styles.project_details_container}>
                        <div>
                            <Typography variant='body2' component={"p"} className={styles.project_details}>Client</Typography>
                            <Typography variant='body2' component={"p"} className={styles.project_details_content}>Mundra LPG Terminal Pvt Ltd</Typography>
                        </div>
                        <div>
                            <Typography variant='body2' component={"p"} className={styles.project_details}>Location</Typography>
                            <Typography variant='body2' component={"p"} className={styles.project_details_content}>Mundra, Gujarat</Typography>
                        </div>
                        <div>
                            <Typography variant='body2' component={"p"} className={styles.project_details}>{"Capacity"}</Typography>
                            <Typography variant='body2' component={"p"} className={styles.project_details_content}>{"1.34 MMTPA"}</Typography>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </>,
        <>
            <Typography variant='body2' component={"h3"} className={styles.project_title}>UGAA & Flare System Project</Typography>
            <Grid className={styles.project_container}>
                <img src="/assets/images/projects/project_ugaa.jpg" alt="project 1" />
                <Grid>
                    <Typography variant='body2' component={"p"} className={styles.project_details_content}>Detailed engineering, supply, construction, supervision of erection & commissioning of Urea Granulation Ammonia Abatement (UGAA) & IBB flare</Typography>
                    <div className={styles.project_details_container}>
                        <div>
                            <Typography variant='body2' component={"p"} className={styles.project_details}>Client</Typography>
                            <Typography variant='body2' component={"p"} className={styles.project_details_content}>SABIC, Saudi Arabia</Typography>
                        </div>
                        <div>
                            <Typography variant='body2' component={"p"} className={styles.project_details}>Location</Typography>
                            <Typography variant='body2' component={"p"} className={styles.project_details_content}>Saudi Arabia</Typography>
                        </div>
                        <div>
                            <Typography variant='body2' component={"p"} className={styles.project_details}>{""}</Typography>
                            <Typography variant='body2' component={"p"} className={styles.project_details_content}>{""}</Typography>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </>,
        <>
            <Typography variant='body2' component={"h3"} className={styles.project_title}>Single Super Phosphate Project</Typography>
            <Grid className={styles.project_container}>
                <img src="/assets/images/projects/project_phosphate.jpg" alt="project 1" />
                <Grid>
                    <Typography variant='body2' component={"p"} className={styles.project_details_content}>Engineering, Procurement & Construction (EPC) of Single Super Phosphate Plant</Typography>
                    <div className={styles.project_details_container}>
                        <div>
                            <Typography variant='body2' component={"p"} className={styles.project_details}>Client</Typography>
                            <Typography variant='body2' component={"p"} className={styles.project_details_content}>Zuari Agro Chemicals Ltd</Typography>
                        </div>
                        <div>
                            <Typography variant='body2' component={"p"} className={styles.project_details}>Location</Typography>
                            <Typography variant='body2' component={"p"} className={styles.project_details_content}>Mahad, Maharashtra</Typography>
                        </div>
                        <div>
                            <Typography variant='body2' component={"p"} className={styles.project_details}>{"Capacity"}</Typography>
                            <Typography variant='body2' component={"p"} className={styles.project_details_content}>{"600 MTPD"}</Typography>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </>,
        <>
            <Typography variant='body2' component={"h3"} className={styles.project_title}>Chlor Alkali Project</Typography>
            <Grid className={styles.project_container}>
                <img src="/assets/images/projects/project_alkali.jpg" alt="project 1" />
                <Grid>
                    <Typography variant='body2' component={"p"} className={styles.project_details_content}>Detailed engineering services & site supervision</Typography>
                    <div className={styles.project_details_container}>
                        <div>
                            <Typography variant='body2' component={"p"} className={styles.project_details}>Client</Typography>
                            <Typography variant='body2' component={"p"} className={styles.project_details_content}>Al Ghanem for Hypochlorite & HCL Co.</Typography>
                        </div>
                        <div>
                            <Typography variant='body2' component={"p"} className={styles.project_details}>Location</Typography>
                            <Typography variant='body2' component={"p"} className={styles.project_details_content}>Saglawya Al-Anbar, Iraq</Typography>
                        </div>
                        <div>
                            <Typography variant='body2' component={"p"} className={styles.project_details}>{"Capacity"}</Typography>
                            <Typography variant='body2' component={"p"} className={styles.project_details_content}>{"60 MTPD"}</Typography>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </>,
        <>
            <Typography variant='body2' component={"h3"} className={styles.project_title}>Phosphoric Acid Tank Project</Typography>
            <Grid className={styles.project_container}>
                <img src="/assets/images/projects/project_alkali.jpg" alt="project 1" />
                <Grid>
                    <Typography variant='body2' component={"p"} className={styles.project_details_content}>Detailed engineering for new phosphoric acid tank</Typography>
                    <div className={styles.project_details_container}>
                        <div>
                            <Typography variant='body2' component={"p"} className={styles.project_details}>Client</Typography>
                            <Typography variant='body2' component={"p"} className={styles.project_details_content}>Phoebe Phosphate Ltd</Typography>
                        </div>
                        <div>
                            <Typography variant='body2' component={"p"} className={styles.project_details}>Location</Typography>
                            <Typography variant='body2' component={"p"} className={styles.project_details_content}>Goa</Typography>
                        </div>
                        <div>
                            <Typography variant='body2' component={"p"} className={styles.project_details}>{"Capacity"}</Typography>
                            <Typography variant='body2' component={"p"} className={styles.project_details_content}>{"3000 MT"}</Typography>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </>,
        <>
            <Typography variant='body2' component={"h3"} className={styles.project_title}>Chlor Alkali Project</Typography>
            <Grid className={styles.project_container}>
                <img src="/assets/images/projects/project_alkali_1.jpg" alt="project 1" />
                <Grid>
                    <Typography variant='body2' component={"p"} className={styles.project_details_content}>Detailed engineering services</Typography>
                    <div className={styles.project_details_container}>
                        <div>
                            <Typography variant='body2' component={"p"} className={styles.project_details}>Client</Typography>
                            <Typography variant='body2' component={"p"} className={styles.project_details_content}>ACC Chlorine</Typography>
                        </div>
                        <div>
                            <Typography variant='body2' component={"p"} className={styles.project_details}>Location</Typography>
                            <Typography variant='body2' component={"p"} className={styles.project_details_content}>KSA</Typography>
                        </div>
                        <div>
                            <Typography variant='body2' component={"p"} className={styles.project_details}>{"Capacity"}</Typography>
                            <Typography variant='body2' component={"p"} className={styles.project_details_content}>{"40 TPD"}</Typography>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </>
    ]

    return (
        <Grid className={styles.home_project_container} >
            <Grid className={styles.home_project_subcontainer}>
                <Typography variant="h2" component={"h2"} className={styles.project_heading}>Projects in Focus</Typography>
                <Typography variant="body2" component={"p"} className={styles.project_content}>Explore our flagship projects that exemplify our commitment to excellence and innovation. From complex oil and gas infrastructure to renewable energy initiatives, our portfolio showcases our ability to deliver high-impact solutions via expertise, meticulous planning, and dedication to pushing the boundaries of engineering and project management.</Typography>
            
                <Grid className="project_slider">
                    <Slider  ref={sliderRef} {...settings}>{projectData}</Slider>
                </Grid>

            </Grid>
            

        </Grid>
    )


}

export default HomeProjects;