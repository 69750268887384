import React from 'react';
import Grid from '@mui/material/Grid2';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';


import styles from './leaderShip.module.css'
import { isMobile } from 'react-device-detect';

const CorporateTeam = () => {

    return (
        <Grid className={styles.leadership_container}>
            <Grid className={styles.leadership_subcontainer} sx={{marginBottom:"160px"}} >
                <Typography variant="h2" component={"h2"} className={`${styles.leader_title} ${styles.leader_bottom}`}
                 sx={{marginBottm: "2rem", paddingLeft: isMobile ? "18px": "0px", paddingRight: isMobile ? "18px": "0px"}}>
                        Corporate Leadership Team
                </Typography>
                <hr style={{color:'#C1C1C1'}}/>
                <Grid className={styles.leader_card_container}>
                    <Card className={styles.corporate_card}>
                        <CardMedia sx={{width: 216,height: 216, borderBottom: "10px solid #00258A" }}
                            image="/assets/images/harish.png"
                            title="harish"
                        />
                        <CardContent sx={{padding:"1rem 0px 3rem 0px"}}>
                            <Typography variant="body2" component={"p"} className={styles.leader_card_title}>Mr. Harish Kumar</Typography>
                            <Typography variant="body2" component={"p"} className={`${styles.leader_card_desig} ${styles.leader_card_desig_color}`}>Head, Business<br />Development</Typography>
                        </CardContent>
                    </Card>
                    <Card className={styles.corporate_card}>
                        <CardMedia sx={{width: 216, height: 216, borderBottom: "10px solid #00258A" }}
                            image="/assets/images/kunal.png"
                            title="kunal"
                        />
                        <CardContent sx={{padding:"1rem 0px 3rem 0px"}}>
                            <Typography variant="body2" component={"p"} className={styles.leader_card_title}>Mr. Kunal Gupta</Typography>
                            <Typography variant="body2" component={"p"} className={`${styles.leader_card_desig} ${styles.leader_card_desig_color}`}>MD's Office</Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Grid>
    )

}

export default CorporateTeam