import React, { useState } from 'react';
import Grid from '@mui/material/Grid2';
import { Typography } from '@mui/material';
import PageBanner from '../../../components/pageBanner';
import AdvantageC from '../../../components/advantageC';

import SupplyForm from './supplyForm';
import ServiceForm from './serviceForm';
import EmpanelForm from './empanelForm';

import styles from './supplyForm.module.css'
import { isMobile } from 'react-device-detect';
// import AboutExplore from '../aboutus/aboutExplore';
// import FaqContainer from './faqContainer';


const SupplyPartner = () => {

    const [tabVal, setTabVal] = useState("supply");

    const bannerPath = [
        {title: 'Home', url: '/'},
        {title: 'Join us', url: ''},
        {title: "Grow together", url: ''}
    ]

const handleTab = (pValue) => {
    setTabVal(pValue)
}


return (
    <Grid sx={{width: "100%"}}>
        <PageBanner title="Grow together" bannerimage="supplypartner_banner.jpg" 
            bannerPath={bannerPath}  />
        {/* <GrowTab  /> */}
        <Grid className={styles.supplyForm_container} sx={{paddingTop: isMobile? "140px" :"240px"}}>
            <Grid className={styles.supplyForm_tabcontainer} sx={{paddingRight: isMobile?"18px":"0px"}}>
                <nav style={{display:"flex", justifyContent:'space-between', width:"100%", backgroundColor:"#F4F4F4"}}>
                    <Grid className={`${styles.tab_btn} ${tabVal === "supply" && styles.active}`} onClick={()=> handleTab("supply")} >
                        <Typography variant="body2" component={"p"} className={styles.supplyForm_subcontent}>
                            Supply partner
                        </Typography>
                    </Grid>
                    <Grid className={`${styles.tab_btn} ${tabVal === "service" && styles.active}`} onClick={()=> handleTab("service")}>
                        <Typography variant="body2" component={"p"} className={styles.supplyForm_subcontent}>
                            Service partner
                        </Typography>
                    </Grid>
                    <Grid className={`${styles.tab_btn} ${tabVal === "empanel" && styles.active}`} onClick={()=> handleTab("empanel")}>
                        <Typography variant="body2" component={"p"} className={styles.supplyForm_subcontent}>
                            Empanel as SME
                        </Typography>
                    </Grid>
                </nav>
            </Grid>
        </Grid>
        { tabVal === "supply" ? <SupplyForm /> : tabVal === "service" ? <ServiceForm /> : <EmpanelForm />  }
        
        <AdvantageC />
    </Grid>
)

}

export default SupplyPartner;