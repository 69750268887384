import React from 'react';
import Grid from '@mui/material/Grid2'

import PageBanner from '../../components/pageBanner';
import PageContent from '../../components/pageContent';
import ProjectClient from '../expertise/projectClient';
import FocusSus from './focusSus';
import AdvantageC from '../../components/advantageC';
import { isMobile } from 'react-device-detect';
import CommitmentSus from './commitmentSus';
import VisionSus from './visionSus';


const Sustainbility = () => {

    const bannerPath = [
        {title: 'Home', url: '/'},
        {title: 'Know us', url: '/'},
        {title: 'Sustainability', url: ''}
    ]

    return (
        <Grid sx={{display: 'flex', flexFlow:'column', width: '100%'}}>
            <PageBanner title= {isMobile ? "Sustainability" : "Sustainability"} bannerimage="sustainibility_banner.jpg" bannerPath={bannerPath} />
            <PageContent details="At Simon India, sustainability is the cornerstone of everything we do. From reducing environmental impact to creating energy-efficient designs, our approach focuses on long-term solutions that benefit the planet and the communities we serve. Through innovative practices and advanced technologies, we aim to lead the way in sustainable construction and operations, paving the path for a greener future."  />
            <VisionSus />
            <CommitmentSus />
            <ProjectClient 
                quotation="At Simon India, I am proud to lead a team where sustainability drives every engineering decision. We push the boundaries of innovation, optimizing resources, reducing our environmental footprint, and partnering with like-minded organizations. By fostering sustainable development, we create long-term value for clients while setting new industry benchmarks in environmental stewardship and community well-being."
                client="Mr. Kunal Gupta"
                designation="MD's Office
"
            />
            <FocusSus />
            <AdvantageC />
        </Grid>
    )

}

export default Sustainbility;
