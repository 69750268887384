import React from 'react';
import Grid from '@mui/material/Grid2';

import styles from './homeResponsibility.module.css'
import { Typography } from '@mui/material';

const HomeResponsibility = () => {

return (
    <Grid className={styles.responsibility_container}>
        <Grid className={styles.responsibility_background}>
            <Grid className={styles.responsibility_subcontainer}>
                <Grid className={styles.mobile_img}>
                    <img src="/assets/images/home_responsibility_mobile.jpg" alt="responsibility" ></img>
                </Grid>
                <Grid className={styles.responsibility_content_container} sx={{width:{xs:"100%", sm:'40%'}}}>
                    <Typography variant='h2' component={"h2"} className={styles.responsibility_title}>Sustainability</Typography>
                    <Typography variant='body2' component={"p"} className={styles.responsibility_content}>
                    At Simon India, sustainability is more than just a commitment. It's an integral part of our business strategy. We are dedicated to reducing environmental impact, promoting resource efficiency, and driving sustainable growth. 
<br/>
<br/>
Through cutting-edge technologies and responsible practices, we ensure that our projects contribute to a cleaner, greener future. Our focus on community empowerment, environmental stewardship, and ethical governance enables us to create long-lasting positive change. Together, we are building a sustainable tomorrow for future generations.

                    </Typography>
                </Grid>
                {/* <Grid columns={{xs:12, sm:7}}sx={{width:'60%'}}>
                    <img src="/assets/images/responsibility.jpg" alt="responsibility" />
                </Grid> */}
            </Grid>
        </Grid>
    </Grid>
)

}

export default HomeResponsibility;