import React, { useState } from 'react';
import Grid from '@mui/material/Grid2';
import { Typography, Card, CardContent, CardMedia } from '@mui/material';
import Slider from 'react-slick';
import { East, West } from '@mui/icons-material';

import styles from './sustainbility.module.css'
import { isMobile } from 'react-device-detect';


const CommitmentSus = () => {


    const [serviceIndex, setServiceIndex] = useState(0);

    const NextArrow = props => {
        const { className, style, onClick } = props;
        return <East
        className={className}
        style={{ ...style, display: "block", right:"-1%", background: "transparent", color: "rgba(33, 130, 255, 1)" }}
        onClick={onClick}
      />
    }
    const PrevArrow = props => {
        const { className, style, onClick } = props;
        return <West
        className={className}
        style={{ ...style, display: "block", left:"-1%", background: "transparent", color: "rgba(33, 130, 255, 1)" }}
        onClick={onClick}
      />
    }

    const settings = {
        dots: false,
        arrows: false,
        dotsClass: 'slick-dots',
        className: 'center',
        centerMode: false,
        infinite: false,
        centerPadding: '20px',
        slidesToShow: 4,
        
        slidesToScroll: Math.min(1),
        swipeToSlide: false,
        speed: 500,
        responsive: [
            { 
            breakpoint: 1025,
                setting: {

                }
            },
            {
                breakpoint: 450,
                settings:{
                    arrows: false,
                    dots: true,
                    infinite: false,
                    centerMode: true,
                    swipeToSlide: true,
                    slidesToShow: 1,
                    afterChange: (e) => setServiceIndex(e),
                    nextArrow: <NextArrow />,
                    prevArrow: <PrevArrow />,
                }
            }
        ]
    }

    const expertDetails = [
        {
            id: 0,
            title: "Integrate energy-efficient designs",
            img:"commit1_img.png",
            content: "Our energy-efficient designs prioritize smart technology and eco-friendly construction, drastically reducing energy consumption. By focusing on resource-efficient methods, we aim to minimize carbon footprints while optimizing the performance of every project. Our designs not only meet industry standards but also exceed expectations by ensuring operational efficiency. We continuously research and implement the latest advancements in energy-efficient technologies to deliver sustainable solutions across all our projects.",
            
        },
        {
            id: 0,
            title: "Adopt renewable energy sources",
            img: "commit2_img.png",
            content: "Simon India actively adopts renewable energy sources to power our projects, from solar energy to wind power. We are committed to reducing dependence on fossil fuels and enhancing our contribution to a cleaner, greener energy future. By leveraging renewable energy, we aim to significantly reduce greenhouse gas emissions and promote long-term environmental sustainability. Our renewable energy initiatives also contribute to lowering operational costs for clients while fostering a responsible approach to energy consumption."
        },
        {
            id: 0,
            title: "Optimize resource use through tech*",
            img: "commit3_img.png",
            content: "Leveraging advanced technologies, we optimize resource consumption in our processes. From water recycling to material reuse, we integrate cutting-edge tech to ensure minimal waste and maximum efficiency at every stage of our projects. Our digital tools and automation systems allow for real-time monitoring of resources, enabling us to make swift adjustments for greater sustainability. This proactive approach not only ensures environmental stewardship but also enhances project timelines and cost-effectiveness.",
        }
    ]

    const serviceData = [
        <>
            <Card sx={{maxWidth: 216, width: 216}} onClick={() => setServiceIndex(0)}>
                <CardMedia
                    sx={{borderRadius:"5px 5px 0px 0px", height: 130}} 
                    title=""
                    className= {serviceIndex !== 0 ? styles.apply_filter : styles.disable_filter}
                    image='/assets/images/commit1_thumb.png'
                />
                <CardContent sx={{position:"relative", padding:"33px 18px", minHeight:"70px", backgroundColor: serviceIndex === 0 ? "#CEE86B": "#FFF"}}>
                    <div style={{ height: '2px',marginBottom:'0.5rem', backgroundColor: serviceIndex === 0 ? "#00258A": "#c1c1c1"}} />
                    <Typography variant='h4' component={'h4'} className={styles.expert_title} sx={{color: serviceIndex === 0 ? "#00258A" : "#5A5A5A" }}>Integrate energy-efficient designs</Typography>
                </CardContent>
            </Card>
        </>,
        <>
            <Card sx={{maxWidth: 216, width: 216}} onClick={() => setServiceIndex(1)}>
                <CardMedia
                    sx={{borderRadius:"5px 5px 0px 0px", height: 130}} 
                    title=""
                    className= {serviceIndex !== 1 ? styles.apply_filter : styles.disable_filter}
                    image='/assets/images/commit2_thumb.png'
                />
                <CardContent sx={{position:"relative", padding:"33px 18px", minHeight:"70px", backgroundColor: serviceIndex === 1 ? "#CEE86B": "#FFF"}}>
                    <div style={{ height: '2px',marginBottom:'0.5rem', backgroundColor: serviceIndex === 1 ? "#00258A": "#c1c1c1"}} />
                    <Typography variant='h4' component={'h4'} className={styles.expert_title} sx={{color: serviceIndex === 1 ? "#00258A" : "#5A5A5A" }}>Adopt renewable energy sources</Typography>
                </CardContent>
            </Card>
        </>,
        <>
            <Card sx={{maxWidth: 216, width: 216}} onClick={() => setServiceIndex(2)}>
                <CardMedia
                    sx={{borderRadius:"5px 5px 0px 0px", height: 130}} 
                    title=""
                    className= {serviceIndex !== 2 ? styles.apply_filter : styles.disable_filter}
                    image='/assets/images/commit3_thumb.png'
                />
                <CardContent sx={{position:"relative", padding:"33px 18px", minHeight:"70px", backgroundColor: serviceIndex === 2 ? "#CEE86B": "#FFF"}}>
                    <div style={{ height: '2px',marginBottom:'0.5rem', backgroundColor: serviceIndex === 2 ? "#00258A": "#c1c1c1"}} />
                    <Typography variant='h4' component={'h4'} className={styles.expert_title} sx={{color: serviceIndex === 2 ? "#00258A" : "#5A5A5A" }}>Optimize resource use through tech* </Typography>
                </CardContent>
            </Card>
        </>
    ]


    return (
        <>
        <Grid className={styles.sustainability_container} 
            sx={{ paddingTop:"80px", paddingBottom: "100px", paddingLeft: isMobile? "36px": "0px", paddingRight: isMobile? "36px": "0px" }}>
            <Grid className={`${styles.sustainability_subcontainer} ${styles.expertise_card}`} 
                >
                <Typography variant="h3" component={"h3"} className={styles.expertise_title} 
                    sx={{width:{xs:"100%", sm:"35%", marginTop: "-5rem"}, textAlign:'left', marginBottom: "30px"}}>Our Commitment To Sustainability</Typography>
                <Grid className={styles.commitment_container} sx={{width:{xs:"100%", sm:"65%"}}}>
                    { !isMobile ? serviceData
                        : <Slider {...settings}> {serviceData} </Slider>
                    }
                    
                </Grid>
            </Grid>
        </Grid>
        <Grid className={`${styles.sustainability_container} ${styles.expertise_area}`} sx={{paddingLeft: "0px", paddingRight: "0px"}} >
            <Grid className={`${styles.sustainability_subcontainer} ${styles.commitment_subarea}`}>
                <img src= {`/assets/images/${expertDetails[serviceIndex].img}`} alt="" />
                <Grid sx={{paddingLeft: isMobile? "36px" : "", paddingRight: isMobile? "36px" : ""}}>
                    <Typography variant="h3" component={"h3"} className={styles.expertise_card_title} 
                        sx={{marginTop:"2rem", width:'100%', marginBottom: "30px"}}>
                        {expertDetails[serviceIndex].title}
                    </Typography>
                    <Grid sx={{display: 'flex', width: '100%', gap: '30px', marginTop:"2rem"}}>
                        <Grid sx={{width: {xs:'100%'}}}>
                            <Typography variant="body2" component={"p"} 
                                className={styles.sustainability_content} 
                                sx={{lineHeight: "30px !important"}}
                            >
                                {expertDetails[serviceIndex].content}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        </>
    )
}

export default CommitmentSus;