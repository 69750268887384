import React from 'react';
import Grid from '@mui/material/Grid2';
import { Typography } from '@mui/material';

import styles from './projectQuality.module.css';
import { isMobile } from 'react-device-detect';

const SafetyOrder = () => {

    return (
        <Grid className={`${styles.safety_container} ${styles.safety_tip_container}`}
            sx={{paddingBottom: "60px", paddingTop: "60px", flexFlow:"column"}}>
            <Grid className={styles.tipBar}>
                <Grid className={styles.tipBarLine}></Grid>
                <Grid className={styles.safety_subcontainer} 
                    sx={{alignItems:"center", display:"flex", zIndex:1}}>
                    <Grid className={styles.barimg_container}>
                        <img src="/assets/images/worker_icon.png" alt="" />
                    </Grid>
                    <Typography variant={'h2'} component={"h2"} className={`${styles.safety_title} ${styles.safetypro_title}`} >Safety Pro Tip</Typography>
                </Grid>
            </Grid>
            <Grid sx={{backgroundColor:"#ECF2F6", width:"100%", display:"flex", justifyContent:"center", zIndex:"-1"}}>
                <Grid className={styles.safety_subcontainer} sx={{padding:"4rem 0rem 3rem"}}>
                    <Grid sx={{width: !isMobile ? "62%" : "100%", paddingLeft: !isMobile ? "0px":"36px", paddingRight: !isMobile ? "0px":"36px", marginTop: isMobile ? "300px":"0px"}}>
                        <Typography variant={'h3'} component={"h3"} className={`${styles.safety_content} ${styles.safety_subcontent}`} >SAFETY is Operational Risk Management</Typography>
                        <Grid sx={{marginBottom:"1rem"}}>
                            <Typography variant={'h4'} component={"h4"} className={styles.safety_content} 
                                sx={{fontWeight: 600, color:"#313131 !important", marginTop:"1rem",fontSize: "1.25rem !important"}}>What is SAFETY for You?</Typography>
                            <Typography variant={'body2'} component={"p"} className={styles.safety_content} 
                                sx={{color:"#313131 !important"}}>It is a Choice YOU make like Smoking despite visual warning on packet.</Typography>
                        </Grid>
                        <Grid>
                            <Typography variant={'h4'} component={"h4"} className={styles.safety_content} 
                                sx={{fontWeight: 600, color:"#313131 !important", marginTop:"1rem",fontSize: "1.25rem !important"}}>Everything is an Operation</Typography>
                            <Typography variant={'body2'} component={"p"} className={styles.safety_content} 
                                sx={{color:"#313131 !important"}}>Being Aware of the Risk, Knowing the consequences and Managing the Risk
                                </Typography>
                        </Grid>
                        <Grid>
                            <Typography variant={'h4'} component={"h4"} className={styles.safety_content} 
                                sx={{fontWeight: 600, color:"#313131 !important", marginTop:"1rem",fontSize: "1.25rem !important"}}>Daily Travel vs. Traveling during rains</Typography>
                            <Typography variant={'body2'} component={"p"} className={styles.safety_content} 
                                sx={{color:"#313131 !important"}}>Slippage due to wet surface makes you drive slow as the increased risk needs to be managed.</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid sx={{position:"absolute", right:0}}>
                <img src="/assets/images/safety_img.png" alt="" style={{float:"right", marginTop: !isMobile ? "75px" : "-448px" , width: isMobile? "85%":"101%"}} />
            </Grid>
        </Grid>
    )
}

export default SafetyOrder;
