import React from 'react';
import Grid from '@mui/material/Grid2';
import PageBanner from '../../components/pageBanner';
import AdvantageC from '../../components/advantageC';
import PageContent from '../../components/pageContent';
import ExploreProject from '../../components/exploreProject';
import ProjectClient from './projectClient';
import ServiceProvide from './serviceProvide';
import ExperienceDetails from './experienceDetails';


const Services = () => {

    const bannerPath = [
        {title: 'Home', url: '/'},
        {title: 'Services', url: ''}
    ]


    return (
        <Grid sx={{width: "100%"}}>

            <PageBanner title="Services" bannerimage="services_banner.png" 
                bannerPath={bannerPath}  />
            <PageContent 
            details="At Simon India, we pride ourselves on offering a holistic range of services that cater to the intricate demands of modern engineering projects. Our expertise spans across various sectors, enabling us to deliver tailored solutions that address specific industry challenges. Whether you are embarking on a new project or optimizing an existing one, our services are designed to support every stage of the project lifecycle. 
<br /><br />
We combine cutting-edge technology, rigorous procedures, and a deep understanding of industry best practices to ensure that your projects are not only successful but also sustainable in the long run. With a dedicated team of experts, we bring a wealth of experience and a commitment to excellence in everything we do. 
<br /><br />
Partner with Simon India to experience a seamless, efficient, and results-driven approach to your engineering and construction needs.
" />
            <ServiceProvide />
            <ProjectClient 
                quotation="For the past nine years at Simon India Limited, the 'working together, winning together' mindset has been key to our project successes and my own professional growth. With structured processes that foster collaboration, I've been able to continuously develop my skills and achieve meaningful career milestones. It's been a privilege to be part of Simon’s growth journey."
                client="Mohammad Waseem" 
                designation="Piping Engineer, Simon India Limited."/>
            <ExperienceDetails />
            <ExploreProject />

            <AdvantageC />
        </Grid>
    )

}

export default Services;